import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageSlider } from 'dbi-pearl-ui-kit';
import { VendorLandingPageSliderStyled } from './VendorLandingPageSlider.styled';
import { usePearlSearch } from '../../contexts/PearlSearchContext';
import { ResponsiveTextElement } from '../../../framework';
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';

const generateCategoryImages = (categories, imageCaptions) => {
    const images = [];

    categories.forEach((c) => {
        images.push({
            ...c.image,
            caption: imageCaptions[c.externalId] || '',
        });
    });

    return images;
};

const VendorLandingPageSlider = (props) => {
    const { t } = useTranslation();
    const { categories, setPrivateSearchContextCategory } = props;
    const { setSearchCategory } = usePearlSearch();
    const { appData } = useStore();
    const navigate = useNavigate();

    const imageCaptions = t('Categories', { returnObjects: true });
    const [categoryImages] = useState(generateCategoryImages(categories, imageCaptions));

    const onImageClick = useCallback((index) => {
        const cat = appData.getCategoryByExternalId(categories[index].externalId);

        if (setPrivateSearchContextCategory) {
            if (appData.getCategoryTravels(cat)) {
                setPrivateSearchContextCategory(cat);
            } else {
                //if cateogry does not travel, initiate a normal VLP Search
                setSearchCategory(cat);
                navigate(APP_ROUTES.vendors);
            }
        } else {
            setSearchCategory(cat);
        }
    }, []);

    return (
        <VendorLandingPageSliderStyled>
            <div data-testid="vlp-slider" className="vlp-slider-section">
                <ResponsiveTextElement className={'vlp-subtitle'} large={'h3'} small={'h2'}>
                    {t('Pages.VendorLandingPage.Subtitle')}
                </ResponsiveTextElement>
                <ImageSlider
                    images={categoryImages}
                    hideCaptions={false}
                    centeredSlides={false}
                    onImageClick={onImageClick}
                    spaceBetween={16}
                />
            </div>
        </VendorLandingPageSliderStyled>
    );
};

export { VendorLandingPageSlider };
