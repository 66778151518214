import { makeAutoObservable } from 'mobx';
import { initAppData } from './Initializers';
import { GeocodeService } from 'lib/apis/GeocodeService';
import { SystemService } from 'lib/apis/SystemService';
import { CategoryImages, DefaultCategoryImage } from '../assets/VLP/categories/CategoryImages';
import { categoryExternalIds } from 'framework';
import { defaultCity, defaultState } from 'event-user/constants/topCities';

export default class AppDataStore {
    categoryList = [];
    categoryListIncludeArchived = [];
    aemUrls = [];
    Loading = false;
    featureToggles = {}; //comes from DB
    featureToggleOverrides = {};
    currentLocation = {};

    topCategoriesList = [
        { externalId: categoryExternalIds.venues },
        {
            externalId: categoryExternalIds.photographyVideography,
        },
        { externalId: categoryExternalIds.florist },
        { externalId: categoryExternalIds.planning },
        { externalId: categoryExternalIds.beauty },
        { externalId: categoryExternalIds.music },
        { externalId: categoryExternalIds.foodBeverage },
    ];

    exploreCategoriesList = [
        { externalId: categoryExternalIds.venues },
        {
            externalId: categoryExternalIds.photographyVideography,
        },
        { externalId: categoryExternalIds.florist },
        { externalId: categoryExternalIds.beauty },
        { externalId: categoryExternalIds.music },
        { externalId: categoryExternalIds.bridalTuxedo },
        { externalId: categoryExternalIds.invitations },
        { externalId: categoryExternalIds.foodBeverage },
        { externalId: categoryExternalIds.planning },
        { externalId: categoryExternalIds.travel },
        { externalId: categoryExternalIds.lodging },
        { externalId: categoryExternalIds.transportation },
        { externalId: categoryExternalIds.healthWellness },
        { externalId: categoryExternalIds.rentalEquipment },
        { externalId: categoryExternalIds.jewelry },
        { externalId: categoryExternalIds.favorsGifts },
        { externalId: categoryExternalIds.photobooths },
        { externalId: categoryExternalIds.officiants },
        { externalId: categoryExternalIds.entertainmentOtherServices },
    ];

    nonTravelCategories = [categoryExternalIds.lodging, categoryExternalIds.venues];
    capacityCategories = [categoryExternalIds.lodging, categoryExternalIds.venues];

    constructor(options) {
        makeAutoObservable(this);
        this.Initialized = initAppData(this);
    }

    setLoading(value) {
        this.Loading = value;
    }

    setCategoryList(list) {
        list.forEach((c) => {
            c.image = CategoryImages[c.externalId] || DefaultCategoryImage;
        });

        this.categoryListIncludeArchived?.replace(list);
        this.categoryList?.replace(filterCategoryList(list));
    }

    getCategoryName(categoryId) {
        return this.categoryListIncludeArchived.find((category) => {
            return category.id === parseInt(categoryId);
        })?.name;
    }

    getCategoryExternalId(categoryId) {
        return this.categoryListIncludeArchived.find((category) => {
            return category.id === parseInt(categoryId);
        })?.externalId;
    }

    getDefaultSearchCategory() {
        return this.categoryListIncludeArchived.find((category) => {
            return category.name === categoryExternalIds.venues;
        });
    }

    getCategoryById(categoryId) {
        return this.categoryListIncludeArchived.find((category) => {
            return category.id === parseInt(categoryId);
        });
    }

    getCategoryByExternalId(externalId) {
        return this.categoryListIncludeArchived.find((category) => {
            return category.externalId === externalId;
        });
    }

    getSubCategoryName(categoryId, subCategoryIds) {
        const category = this.categoryListIncludeArchived.find((category) => {
            return category.id === parseInt(categoryId);
        });
        return category.subCategories
            .filter((subCategory) => subCategoryIds.indexOf(subCategory.id) > -1)
            .map((x) => x.name)
            .join(', ');
    }

    getCategoryTravels = (category) => {
        return !this.nonTravelCategories.includes(category?.externalId);
    };

    setFeatureToggles(toggles) {
        toggles?.forEach((f) => (this.featureToggles[f.featureName] = parseInt(f.enabled)));
    }

    isFeatureEnabled(featureName) {
        return this.featureToggleOverrides.hasOwnProperty(featureName)
            ? this.featureToggleOverrides[featureName]
            : this.featureToggles[featureName];
    }

    // will use event (if provided), then try system location, then use default
    async getCurrentLocation(event = null) {
        const systemService = SystemService();
        const geocodeService = GeocodeService();
        let loc;

        let eventLoc;
        if (event && event.EventLocation) {
            eventLoc = event.EventLocation[0];
        }

        // use the event location if valid
        if (eventLoc?.latitude && eventLoc?.longitude && eventLoc?.displayText) {
            loc = eventLoc;
        } else {
            try {
                // or try to get app location
                const whereIAm = await systemService.getWhereAmI();
                if (whereIAm?.latitude && whereIAm?.longitude) {
                    loc = await geocodeService.getReverseGeoCode(
                        whereIAm.latitude,
                        whereIAm.longitude
                    );
                }
                // or use default city
                if (!loc?.latitude || !loc?.longitude) {
                    loc = await geocodeService.getGeocodeByAddress(defaultCity, defaultState);
                }
            } catch {}
        }

        if (!loc?.latitude || !loc?.longitude) {
            loc = {};
        }

        return loc;
    }
}

const filterCategoryList = (categoryList) => {
    if (categoryList) {
        const filteredCats = filterArchived(categoryList);
        filteredCats.forEach((c) => {
            c.subCategories = filterArchived(c.subCategories);
            c.serviceTypes = filterArchived(c.serviceTypes);
            c.serviceTypes?.forEach((st) => (st.services = filterArchived(st.services)));
            c.styles = filterArchived(c.styles);
        });
        return filteredCats;
    }
};

const filterArchived = (list) => {
    return list?.filter((c) => !c.archived);
};
