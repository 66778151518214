import { eventTypes, userEventRoles } from '../../../constants/accountCreationOptions';
import { useTranslation } from 'react-i18next';
import { FormRow, PearlError } from 'framework';
import { Dropdown, TextInput } from 'dbi-pearl-ui-kit';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const UserBasics = () => {
    const { t } = useTranslation();
    const { register } = useFormContext();

    const agreeField = 'Agree';

    register(agreeField, { value: false });

    return (
        <div>
            <FormRow
                columns={[
                    <div key={0}>
                        <TextInput id={0} formFieldName="FirstName" />
                        <PearlError name="FirstName" />
                    </div>,
                ]}
            />
            <FormRow
                columns={[
                    <div key={0}>
                        <TextInput formFieldName="LastName" />
                        <PearlError name="LastName" />
                    </div>,
                ]}
            />
            <FormRow
                columns={[
                    <div key={0}>
                        <Dropdown formFieldName="EventType">
                            {eventTypes.map((option) => {
                                return (
                                    <option
                                        key={option.value}
                                        value={t(option.value)}
                                        data-testid={`event-type-option-${option.value}`}
                                    >
                                        {t(option.label)}
                                    </option>
                                );
                            })}
                        </Dropdown>
                        <PearlError name="EventType" />
                    </div>,
                ]}
            />
            <FormRow
                columns={[
                    <div key={0}>
                        <Dropdown formFieldName="UserEventRole">
                            {userEventRoles.map((option) => {
                                return (
                                    <option
                                        key={option.value}
                                        value={t(option.value)}
                                        data-testid={`user-role-option-${option.value}`}
                                    >
                                        {t(option.label)}
                                    </option>
                                );
                            })}
                        </Dropdown>
                        <PearlError name="UserEventRole" />
                    </div>,
                ]}
            />
        </div>
    );
};

export { UserBasics };
