import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { VendorLandingPageSlider } from 'event-user/components/VendorLandingPageSlider/VendorLandingPageSlider';
import { APP_ROUTES } from 'shared/constants/navigation';
import { GlobalFooter } from 'shared/components/GlobalFooter/GlobalFooter';
import { GlobalNavigation } from 'shared/components/Navigation/GlobalNavigation/GlobalNavigation';
import { MobileFooterNavigation } from 'shared/components/Navigation/MobileFooterNavigation/MobileFooterNavigation';
import { NotFoundPageStyled } from './NotFoundPage.styled';
import { newPageTrigger } from 'LivePerson';
import { useStore } from '../../../stores/Store';

const NotFoundPage = () => {
    const { t } = useTranslation();
    const { appData } = useStore();

    useEffect(() => {
        newPageTrigger(window.location.href);
    }, [window.location.href]);

    return (
        <NotFoundPageStyled>
            <GlobalNavigation />
            <div className="content">
                <div className="message">
                    <h1 className="error">{t('NotFound.Oops')}</h1>
                    <p className="p1">
                        <Trans
                            i18nKey={'NotFound.Message'}
                            components={{
                                home: <Link to={APP_ROUTES.landing} />,
                            }}
                        />
                    </p>
                </div>
                <div className="slider">
                    <VendorLandingPageSlider categories={appData.categoryList} />
                </div>
            </div>
            <MobileFooterNavigation />
            <GlobalFooter />
        </NotFoundPageStyled>
    );
};

export { NotFoundPage };
