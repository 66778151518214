import React, { useState, useEffect, lazy } from 'react';
import { PearlIcon, IconName, IconSizes, TruncatedTextDisplay } from 'dbi-pearl-ui-kit';
import PropTypes from 'prop-types';
import { Button, SearchContainer, VerticalLine } from './SearchButton.styled';
import { usePearlSearch } from '../../../contexts/PearlSearchContext';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';
const VendorSearchDrawer = lazy(() => import('../../Search/VendorSearchDrawer/VendorSearchDrawer'));
const LocationSearchDrawer = lazy(() =>
    import('../../Search/LocationSearchDrawer/LocationSearchDrawer')
);

export default function SearchButton(props) {
    const { openEmptyDrawersOnload } = props;
    const { category, setSearchLocation, location, getSearchPath, setSearchCategory, filters } =
        usePearlSearch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const browserLocation = useLocation();
    const [vendorSearchDrawerOpen, setVendorSearchDrawerOpen] = useState(
        openEmptyDrawersOnload ? !category : false
    );

    const [locationSearchDrawerOpen, setLocationSearchDrawerOpen] = useState(
        openEmptyDrawersOnload ? !vendorSearchDrawerOpen && !location?.displayText : false
    );

    useEffect(() => {
        if (location?.latitude && location?.longitude) {
            setLocationSearchDrawerOpen(false);
        }
        if (category) {
            setVendorSearchDrawerOpen(false);
        }
    }, [location, category]);

    const toggleSearchByVendor = () => {
        setLocationSearchDrawerOpen(false);
        setVendorSearchDrawerOpen(!vendorSearchDrawerOpen);
    };

    const toggleSearchByLocation = () => {
        setVendorSearchDrawerOpen(false);
        setLocationSearchDrawerOpen(!locationSearchDrawerOpen);
    };

    const handleOnCategorySearch = (cat) => {
        const currentPathname = browserLocation.pathname;
        if (currentPathname.includes(APP_ROUTES.vendors)) {
            setSearchCategory(cat);
        } else if (currentPathname.includes(APP_ROUTES.nationalTravelVendors)) {
            setSearchCategory(cat);
            navigate(`${APP_ROUTES.nationalTravelVendors}/${cat?.externalId}`);
        } else {
            setSearchCategory(cat);
            navigate(getSearchPath(cat, location, filters));
        }
        toggleSearchByVendor();
    };

    const handleOnLocationSearch = (loc) => {
        if (loc) {
            setSearchLocation(loc);
        }
        if (category) {
            if (browserLocation.pathname.includes(APP_ROUTES.vendors)) {
                setSearchLocation(loc);
            } else {
                navigate(getSearchPath(category, loc, filters));
            }
        } else {
            setSearchLocation(loc);
        }
        toggleSearchByLocation();
    };

    return (
        <>
            <SearchContainer>
                <Button
                    data-testid="left-button"
                    onClick={() => {
                        toggleSearchByVendor();
                    }}
                >
                    <TruncatedTextDisplay
                        maxWidth={'110px'}
                        lineCount={1}
                        textAlign={'left'}
                        className="makeItTruncateActually"
                    >
                        {category?.name ? (
                            <>{category?.name}</>
                        ) : (
                            <b className="p3Emphasis">{t('SearchButton.VendorTitle')}</b>
                        )}
                    </TruncatedTextDisplay>
                </Button>
                <VerticalLine />
                <Button
                    data-testid="right-button"
                    onClick={() => {
                        toggleSearchByLocation();
                    }}
                >
                    <TruncatedTextDisplay
                        maxWidth={'110px'}
                        lineCount={1}
                        textAlign={'left'}
                        className="makeItTruncateActually"
                    >
                        {location?.displayText ? (
                            <>{location?.displayText}</>
                        ) : (
                            <b className="p3Emphasis">{t('SearchButton.LocationTitle')}</b>
                        )}
                    </TruncatedTextDisplay>
                </Button>
                <PearlIcon iconName={IconName.SEARCH} size={IconSizes.MD} />
            </SearchContainer>
            {vendorSearchDrawerOpen && (
                <VendorSearchDrawer
                    onSearch={handleOnCategorySearch}
                    active={vendorSearchDrawerOpen}
                    toggleActive={toggleSearchByVendor}
                />
            )}
            {locationSearchDrawerOpen && (
                <LocationSearchDrawer
                    onSearch={handleOnLocationSearch}
                    active={locationSearchDrawerOpen}
                    toggleActive={toggleSearchByLocation}
                />
            )}
        </>
    );
}

SearchButton.propTypes = {
    leftSideTitle: PropTypes.string,
    leftSideSubtitle: PropTypes.string,
    onLeftSideClick: PropTypes.func,
    rightSideTitle: PropTypes.string,
    rightSideSubtitle: PropTypes.string,
    onRightSideClick: PropTypes.func,
    onSearchClick: PropTypes.func,
};
