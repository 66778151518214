import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const VendorLandingPageSliderStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    padding-bottom: 24px;

    .vlp-subtitle {
        align-self: start;
    }

    .vlp-slider-section {
        align-self: center;
        width: 90%;
    }

    .slide-caption-container {
        position: static;
        text-align: center;
    }

    .swiper-slide {
        max-width: 120px;
        img {
            height: 120px;
            width: 120px;
            cursor: pointer;
        }
    }

    .slide-caption {
        height: 50px;
        flex-wrap: wrap;
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 55px;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        width: 100%;
    }
`;

export { VendorLandingPageSliderStyled };
