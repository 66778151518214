import { useTranslation } from 'react-i18next';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu';
import { GlobalNavigationStyled } from './GlobalNavigation.styled';
import {
    DavidsBridalTopBar,
    DiamondTopBar,
    PearlTopBar,
    PearlBlack,
} from 'assets/logos/LogoImages';
import { SearchControls } from 'event-user/components/SearchControls/SearchControls';
import { APP_ROUTES, EXTERNAL_LINKS, navigation, vendorRoot } from '../../../constants/navigation';
import { UserNavigationMenu } from '../UserNavigationMenu/UserNavigationMenu';
import { NewTabLink, useIsScreenLarge, useIsScreenTablet, PearlApps } from 'framework';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { useWebstore } from 'shared/contexts/PearlWebstoreContext';
import { useIsInViewportRef } from 'shared/hooks/useIsInViewport';
import { getAppName } from 'shared/hooks/AppName';

const InternalLink = (props) => {
    const { link, translator } = props;

    return (
        <Link to={link.src} state={link.stateToSend}>
            <label>
                <b>{translator(link.displayName)}</b>
            </label>
        </Link>
    );
};

const ExternalLink = (props) => {
    const { link, translator } = props;

    return (
        <NewTabLink link={link.src} target={'_blank'}>
            <label>
                <b>{translator(link.displayName)}</b>
            </label>
        </NewTabLink>
    );
};

const GlobalNavigation = (props) => {
    const { children } = props;

    const topBarRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isScreenLarge = useIsScreenLarge();
    const isScreenTablet = useIsScreenTablet();

    const { authUser } = useAuthUserContext();
    const { webstore } = useWebstore();
    const [needsTopBar, setNeedsTopBar] = useState(true);

    const isHomePage = location?.pathname === '/';
    const topBarIsInViewport = useIsInViewportRef(topBarRef);
    const isBottomBarFixedTop = !topBarIsInViewport && isHomePage;
    const appName = getAppName();

    const displayNavSearchControls =
        (webstore && location?.pathname === `/${webstore.WebstoreUrl}`) || !isScreenTablet;

    const mobileTopBarRoutes = [
        '/account',
        APP_ROUTES.dashboard,
        APP_ROUTES.inbox,
        APP_ROUTES.account.eventInformation,
        APP_ROUTES.account.favorites,
    ];

    let bottomBarLinks;

    if (authUser.isAuthedVendor && appName === PearlApps.VENDOR) {
        bottomBarLinks = navigation.vendorUserGlobalBottomBar;
    } else if (authUser.isAuthedEventUser && appName === PearlApps.EVENT_USER) {
        bottomBarLinks = navigation.eventUserGlobalBottomBar;
    } else {
        bottomBarLinks = navigation.unauthedGlobalBottomBar;
    }

    useEffect(() => {
        if (!isScreenLarge) {
            for (let x = 0; x < mobileTopBarRoutes.length; x++) {
                if (location?.pathname?.includes(mobileTopBarRoutes[x])) {
                    setNeedsTopBar(false);
                }
            }
        } else {
            if (
                location?.pathname?.includes(vendorRoot) &&
                !location?.pathname?.includes(APP_ROUTES.inbox)
            ) {
                setNeedsTopBar(false);
            }
        }
    }, [location?.pathname, isScreenLarge]);

    return (
        <GlobalNavigationStyled
            data-testid="global-navigation"
            className={isBottomBarFixedTop ? 'fixedBottomBar' : ''}
        >
            {!!needsTopBar && (
                <div className="topBar" ref={topBarRef}>
                    <div className="companySiteButtons">
                        <a
                            data-testid="davids-bridal-logo-link"
                            href={EXTERNAL_LINKS.davidsBridal}
                            aria-label={`${t('Navigation.AriaLabel')} ${t(
                                'Navigation.GlobalNavigationBar.DavidsBridal'
                            )}`}
                            className="siteButton"
                            target="_self"
                        >
                            <img
                                className="logo"
                                src={DavidsBridalTopBar.default}
                                alt={t('Navigation.GlobalNavigationBar.DavidsLogoAlt')}
                            />
                        </a>
                        <Link
                            data-testid="pearl-landing-logo-link"
                            to={APP_ROUTES.landing}
                            aria-label={`${t('Navigation.AriaLabel')} ${t(
                                'Navigation.GlobalNavigationBar.Pearl'
                            )}`}
                            className="siteButton pearlButton"
                        >
                            <img
                                className="logo"
                                src={PearlTopBar.default}
                                alt={t('Navigation.GlobalNavigationBar.PearlLogoAlt')}
                            />
                        </Link>
                        <a
                            data-testid="diamond-by-davids-logo-link"
                            href={EXTERNAL_LINKS.diamond}
                            aria-label={`${t('Navigation.AriaLabel')} ${t(
                                'Navigation.GlobalNavigationBar.Diamond'
                            )}`}
                            className="siteButton"
                            target="_self"
                        >
                            <img
                                className="logo"
                                src={DiamondTopBar.default}
                                alt={t('Navigation.GlobalNavigationBar.DiamondLogoAlt')}
                            />
                        </a>
                    </div>

                    {!!isScreenLarge && !authUser.isAuthedVendor && (
                        <div className="ctaSection">
                            <button
                                className="becomeVendorButton"
                                onClick={() => navigate(APP_ROUTES.aem.join.root)}
                            >
                                <PearlIcon
                                    iconName={IconName.CHAMPAGNE_GLASSES}
                                    size={IconSizes.MD}
                                />
                                <label>{t('Navigation.GlobalNavigationBar.BecomeVendor')}</label>
                            </button>
                        </div>
                    )}
                </div>
            )}
            <div className="bottomBar">
                <div className="leftBottomBar">
                    <div className="hamburger">
                        <HamburgerMenu />
                    </div>
                    <button
                        id="pearl-landing-logo-desktop-link"
                        onClick={() => navigate(APP_ROUTES.landing)}
                        className="logoContainer"
                        aria-label={`${t('Navigation.AriaLabel')} ${t(
                            'Navigation.GlobalNavigationBar.Pearl'
                        )}`}
                    >
                        <img
                            className="headerLogo"
                            src={PearlBlack.default}
                            alt={t('Navigation.GlobalNavigationBar.PearlLogoAlt')}
                        />
                    </button>
                    <div className="linksContainer">
                        {bottomBarLinks.map((link) => {
                            if (link.external) {
                                return (
                                    <ExternalLink
                                        link={link}
                                        translator={t}
                                        key={link.displayName}
                                    />
                                );
                            } else {
                                return (
                                    <InternalLink
                                        link={link}
                                        translator={t}
                                        key={link.displayName}
                                    />
                                );
                            }
                        })}
                    </div>
                    {displayNavSearchControls && (
                        <div
                            className="searchControls"
                            id="navigation-search-controls"
                            data-testid="navigation-search-controls"
                        >
                            <SearchControls openEmptyDrawersOnload={false} isVDP={true} />
                        </div>
                    )}
                </div>
                <div className="userControls">
                    <UserNavigationMenu />
                </div>
            </div>
            {children}
        </GlobalNavigationStyled>
    );
};

export { GlobalNavigation };
