import { PearlApiClient, WebstoreSectionTypes } from 'framework';
import { getAppName } from 'shared/hooks/AppName';
import { unwrapResponse } from '../../../lib/apis/utils';

export function WebstoreService() {
    const appName = getAppName();
    const api = PearlApiClient(appName);

    const keyMap = {
        id: 'Id',
        draftWebstoreId: 'DraftWebstoreId',
        vendorId: 'VendorId',
        name: 'WebstoreName',
        categoryId: 'VendorCategoryId',
        subCategoryIds: 'VendorSubCategoryIds',
        url: 'WebstoreUrl',
        serviceTypes: 'ServiceTypes',
        travels: 'Travels',
        travelOption: 'TravelOption',
        physicalStore: 'PhysicalStore',
        displayStoreAddress: 'DisplayStoreAddress',
        onlineStore: 'OnlineStore',
        shippingOption: 'ShippingRange',
        styles: 'Styles',
        priceTier: 'SelectedPriceTier',
        businessDescription: 'BusinessSummary',
        links: 'SocialLinks',
        capacity: 'Capacity',
        media: 'Media',
        googlePlaceId: 'GooglePlaceId',
        packages: 'Packages',
        faqs: 'Faqs',
        awards: 'Awards',
        partners: 'Partners',
        serviceVibes: 'ServiceVibes',
        addresses: 'Addresses',
        serviceAddressIsBusinessAddress: 'ServiceAddressIsBusinessAddress',
        isCYB: 'IsCYB',
        cybContactEmail: 'CybContactEmail',
    };

    const propsMap = {
        onlineAllowed: 'OnlineAllowed',
    };

    const Sections = [
        {
            key: WebstoreSectionTypes.ABOUT,
            previewPosition: 1,
        },
        {
            key: WebstoreSectionTypes.AMENITIESANDSERVICES,
            previewPosition: 2,
        },
        {
            key: WebstoreSectionTypes.AVAILABILITY_CALENDAR,
            previewPosition: 3,
        },
        {
            key: WebstoreSectionTypes.REVIEWS,
            previewPosition: 4,
        },
        {
            key: WebstoreSectionTypes.GALLERY,
            previewPosition: 5,
        },
        {
            key: WebstoreSectionTypes.PACKAGES,
            previewPosition: 6,
        },
        {
            key: WebstoreSectionTypes.FAQS,
            previewPosition: 7,
        },
        {
            key: WebstoreSectionTypes.PARTNERS,
            previewPosition: 8,
        },
        {
            key: WebstoreSectionTypes.AWARDS,
            previewPosition: 9,
        },
    ];

    // API always strips invalid src
    const searchWebstores = async (params) => {
        const defaultParams = {
            page: 1,
            pageSize: 12,
            sort: null,
        };
        const queryParams = {
            ...defaultParams,
            ...params,
        };
        const res = await api.get(`webstoresearch`, {
            ...queryParams,
        });

        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    const propertyMapper = (obj, propertiesMap) => {
        const mappedProperties = Object.keys(obj)?.reduce(
            (acc, key) => ({
                ...acc,
                ...{ [propertiesMap[key] || key]: obj[key] },
            }),
            {}
        );

        return mappedProperties;
    };

    const getWebstoreByUrl = async (webstoreURL, options = {}) => {
        const res = await api.get(`webstores/url/${webstoreURL}`, {
            removeInvalidImgSrc: true,
            getPublished: options.getPublished,
        });

        if (res?.ok) {
            res.data.Sections = Sections;
            const categoryList = await getCategoryList();
            res.data.VendorCategoryName = mapCategoryIdToName(res.data.categoryId, categoryList);
            res.data.VendorCategoryExternalId = mapCategoryIdToExternalId(
                res.data.categoryId,
                categoryList
            );
            res.data.VendorSubCategoryName = mapSubCategoryIdToName(
                res.data.categoryId,
                res.data.subCategoryId,
                categoryList
            );
            res.data = mapSocialLinks(res.data);

            //map res.data obj to webstore properties
            let webstore = propertyMapper(res.data, keyMap);

            //map props obj to webstore properties
            const webstoreProps = propertyMapper(webstore.props, propsMap);

            webstore = { ...webstore, ...webstoreProps };

            return webstore;
        }
    };

    // TODO: alpha mvp is one-to-one user/vendor/webstore
    const getWebstoreByUserId = async (userId) => {
        const res = await api.get(`users/${userId}/webstores`);
        if (!res.ok || !res.data) {
            return null;
        }

        // TODO: Load the last used webstore (if it's in the list).
        // If none, use the first one.

        if (res?.data[0]?.id) {
            const store = await api.get(`webstores/${res.data[0].id}`, {
                removeInvalidImgSrc: false,
            });
            return store.data;
        } else {
            return null;
        }
    };

    const getWebstoresByName = async (webstoreName) => {
        if (webstoreName?.length > 3) {
            const res = await api.get(`webstores/name/${webstoreName}`);
            return res.data;
        } else {
            return [];
        }
    };

    const getWebstoreAvailability = async (webstoreId, start, end) => {
        const res = await api.get(
            `webstores/${webstoreId}/availability?startDate=${start}&endDate=${end}`
        );
        if (res?.data?.length > 0) {
            res.data.forEach((x) => {
                x.date = new Date(x.availabilityDate.replace('-', '/'));
            });
            return res.data;
        }
        return [];
    };

    const getCategoryList = async () => {
        const res = await api.get('vendorcategories');
        if (res?.ok) {
            return res.data;
        }
        return [];
    };

    const mapCategoryIdToName = (categoryId, categoryList) => {
        if (categoryId && categoryList) {
            const categoryName = categoryList.find((category) => {
                return category.id === parseInt(categoryId);
            })?.name;
            return categoryName;
        }
    };

    const mapCategoryIdToExternalId = (categoryId, categoryList) => {
        if (categoryId && categoryList) {
            const categoryExternalId = categoryList.find((category) => {
                return category.id === parseInt(categoryId);
            })?.externalId;
            return categoryExternalId;
        }
    };

    const mapSubCategoryIdToName = (categoryId, subCategoryId, categoryList) => {
        if (subCategoryId && categoryId && categoryList) {
            const categoryName = categoryList.find((category) => {
                return category.id === parseInt(categoryId);
            });
            const subCategoryName = categoryName.subCategories.find((subCategory) => {
                return subCategory.id === parseInt(subCategoryId);
            })?.name;
            return subCategoryName;
        }
    };

    const mapSocialLinks = (webstore) => {
        webstore.links.forEach((link) => {
            webstore[link.socialMediaName] = link.link;
        });
        return webstore;
    };

    const reportWebstore = async (webstoreId, body) => {
        return await api.post(`webstores/${webstoreId}/report`, body);
    };

    const getWebstoreKPIs = async (webstoreId, kpiBody) => {
        return await api.put(`webstores/${webstoreId}/kpis`, kpiBody);
    };

    const getWebstoresByCategory = async (catId, limitAmount, webstorePoolLimit) => {
        const res = await api.get(
            `webstores/category/${catId}/${limitAmount}/${webstorePoolLimit}`
        );

        if (res?.ok) {
            return res.data;
        }
        return null;
    };

    //returned by API sorted in ascending order of category ordinal
    const getWebstoresByIds = async (ids) => {
        const res = await api.post('/webstores/listIds', {
            ids: ids,
        });

        if (!res.ok || !res.data) {
            return res;
        }

        return res.data;
    };

    const beginClaimWebstore = async (webstoreId) => {
        const res = await api.put(`/webstores/${webstoreId}/beginClaim`);
        return unwrapResponse(res);
    };

    const claimWebstore = async (webstoreId, code) => {
        const payload = { code };
        const res = await api.put(`/webstores/${webstoreId}/claim`, payload);
        return unwrapResponse(res);
    };

    return {
        searchWebstores,
        getWebstoreByUrl,
        getWebstoreAvailability,
        getWebstoreByUserId,
        getWebstoresByName,
        getCategoryList,
        reportWebstore,
        getWebstoreKPIs,
        getWebstoresByCategory,
        getWebstoresByIds,
        beginClaimWebstore,
        claimWebstore,
    };
}
