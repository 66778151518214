import { translations } from 'framework';

const framework = translations.en.translation;

const BRAND = "Pearl by David's";

const common = {
    Forms: {
        Labels: {
            Email: 'email',
            EmailAddress: 'email address',
            Password: 'password',
            UserName: 'user name',
            VendorName: 'vendor name',
            WebstoreName: 'vendor name',
            VendorCategory: 'vendor category',
            VendorSubCategory: 'vendor subcategory',
            SelectCategory: 'select category',
            SelectSubCategory: 'select subcategory',
            EnterPassword: 'enter password',
            ConfirmPassword: 'confirm password',
            PleaseSelect: 'please select',
            Selection: 'selection',
            VendorBusinessAddressLine1: 'company address line 1',
            VendorBusinessAddressLine2: 'company address line 2',
            VendorBusinessAddressCity: 'city',
            VendorBusinessAddressState: 'state',
            VendorBusinessAddressZipcode: 'zipcode',
            PearlWebstoreUrl: 'pearl page url',
            Back: 'BACK',
            Next: 'NEXT',
            Skip: 'SKIP',
            Complete: 'COMPLETE',
            AddNew: 'add new',
            Steps: '{{completedSteps}} of {{totalSteps}}',
            FirstName: 'first name',
            LastName: 'last name',
            EventType: 'event type',
            UserEventRole: 'role',
            GuestCount: 'Guest information',
            BudgetTier: 'Budget information',
            ContactAndInquire: {
                Signup: 'sign up to inquire',
                Inquire: 'contact & inquire',
                GoToConversation: 'see my message',
            },
            Share: 'share vendor button',
            AddVendor: 'add vendor button',
            Birthday: 'birthday',
            ClickHere: 'click here',
            NewPassword: 'new password',
            UpdatePassword: 'update password',
            CurrentPassword: 'current password',
            ReEnterPassword: 're-enter password',
            PhoneNumber: 'phone number',
            SignupAgreement: 'Agreement to the terms, conditions and representations',
            Event: 'event',
        },
        Validation: {
            CannotExceedCharacterLimit: 'cannot exceed {{characterLimit}} characters',
            FieldRequired: '{{field}} is required',
            InvalidField: 'invalid {{field}}',
            InvalidPassword:
                'Your password must contain at least one number, one special character, and have a mixture of uppercase and lowercase letters',
            PasswordCharacterMinimum: 'password must be at least 8 characters',
            PasswordsMustMatch: 'passwords must match',
            VerificationCodeRequired: 'A verification code is required',
            WebstoreNameExists: 'pearl web store url exists',
            ExactCharacterCount: '{{field}} must be {{characterCount}} characters',
            FieldCannotContainNumbers: '{{field}} cannot contain numbers 0-9',
            UserLoginForm: {
                UserEmailRequired: 'email is required',
                UserPasswordRequired: 'password is required',
                InvalidUsername: 'invalid email',
            },
            EventUserEventDateForm: {
                FlexibleRequiredWhenTimeline: 'Timeline is required when your date is flexible',
                FormCantBeEmpty: 'Event date or timeline is required',
            },
        },
        Placeholders: {
            EnterEmail: 'Enter your email',
            EnterPassword: 'Enter your password',
            Select: 'select',
            StartTyping: 'start typing...',
        },
        RequiredFieldsNote: 'Fields indicated with a (*) are required.',
        Names: {
            BusinessAddress: 'BusinessAddress',
        },
        Signup: {
            EmailOptIn: `Sign up for promotional emails and updates from ${BRAND}`,
            Agree: 'I Agree',
        },
    },
    Actions: {
        Copy: 'copy',
        Duplicate: 'duplicate',
        Edit: 'edit',
        Delete: 'delete',
        Publish: 'publish',
        Unpublish: 'unpublish',
        ChangeTier: 'switch to {{tierName}}',
        Reset: 'reset',
        Open: 'open',
        SetDefault: 'set as default',
        SetActive: 'set active',
        SeeMore: 'see more',
        GoTo: 'go to',
    },
    Names: {
        Webstore: 'pearl page',
    },
    Categories: {
        music: 'bands & djs',
        beauty: 'beauty salons',
        foodbeverage: 'catering & bar',
        bridaltuxedo: 'dresses',
        entertainmentotherservices: 'entertainment services',
        favorsgifts: 'favors & gifts',
        florist: 'florists',
        healthwellness: 'health & wellness',
        home: 'home',
        invitationsstationery: 'invitations & stationery',
        jewelry: 'jewelry',
        lodging: 'lodging',
        officiants: 'officiants',
        photobooths: 'photobooths',
        photographyvideography: 'photography',
        rentalequipment: 'rental & equipment',
        transportation: 'transportation',
        tuxedos: 'tuxedos',
        venues: 'venues',
        videography: 'videography',
        weddingcakes: 'wedding cakes',
        planning: 'wedding planner',
        otherservices: 'other services',
    },
};

const routePrefix = BRAND;
const baseTitle = (loc) => `${routePrefix} - ${loc}`;
const vendorTitle = (loc) => `${routePrefix} - Vendor ${loc}`;
const routeTitles = {
    '/': routePrefix,
    '/authcallback': routePrefix,
    '/signup': baseTitle('Signup'),
    '/welcome': baseTitle('Welcome'),
    '/login': baseTitle('Login'),
    '/dashboard': baseTitle('Dashboard'),
    '/inbox': baseTitle('Inbox'),
    '/setup': baseTitle('Setup'),
    '/logout': baseTitle('Logout'),
    '/planning': baseTitle('Planning'),
    '/account-settings': baseTitle('Settings'),
    '/account-information': baseTitle('Account Information'),
    '/event-information': baseTitle('Event Information'),
    '/favorites': baseTitle('Favorites'),
    '/vendor/signup': baseTitle('Signup'),
    '/vendor/login': baseTitle('Login'),
    '/vendor/welcome': baseTitle('Welcome'),
    '/vendor/dashboard': vendorTitle('Dashboard'),
    '/vendor/inbox': vendorTitle('Inbox'),
    '/vendor/help': vendorTitle('Help'),
    '/vendor/prices': vendorTitle('Prices'),
    '/vendor/terms-of-service': vendorTitle('Terms of Service'),
    '/vendor/vendor-agreement': vendorTitle('Agreement'),
    '/vendor/analytics': vendorTitle('Analytics'),
    '/vendor/billing-history': vendorTitle('Billing'),
    '/vendor/account-contracts': vendorTitle('Contracts'),
    '/vendor/account-information': vendorTitle('Information'),
    '/vendor/account-settings': vendorTitle('Settings'),
    '/vendor/account-subscriptions': vendorTitle('Subscriptions'),
    '/vendor/webstores': vendorTitle('Locations'),
    '/vendor/webstore': baseTitle('Pearl Page'),
    '/vendor/webstoresetup/details': baseTitle('Details'),
    '/vendor/webstoresetup/address': baseTitle('Address'),
    '/vendor/webstoresetup/servicearea': baseTitle('Service Area'),
    '/vendor/webstoresetup/services': baseTitle('Services'),
    '/vendor/webstoresetup/servicevibes': baseTitle('Service Vibes'),
    '/vendor/webstoresetup/pricetier': baseTitle('Price Tier'),
    '/vendor/webstoresetup/summary': baseTitle('Summary'),
    '/vendor/webstoresetup/social': baseTitle('Social'),
    '/vendor/webstoresetup/media': baseTitle('Photos'),
    '/vendor/webstoresetup/capacity': baseTitle('Capacity'),
    '/vendor/webstore/editabout': baseTitle('Pearl Page Basics'),
    '/vendor/webstore/editamenities': baseTitle('Services &amp; Amenities'),
    '/vendor/webstore/editavailability': baseTitle('Calendar'),
    '/vendor/webstore/editreviews': baseTitle('Reviews'),
    '/vendor/webstore/editpackages': baseTitle('Packages'),
    '/vendor/webstore/editfaqs': baseTitle('FAQs'),
    '/vendor/webstore/editpartners': baseTitle('Business Besties'),
    '/vendor/webstore/editawards': baseTitle('Awards &amp; Certificates'),
    '/vendor/webstoresetup': baseTitle('Setup'),
};

const en = {
    translation: {
        Brand: BRAND,
        Actions: {
            ...common.Actions,
            EditWebstore: `${common.Actions.Edit} ${common.Names.Webstore}`,
            PublishWebstore: `${common.Actions.Publish} ${common.Names.Webstore}`,
        },
        Categories: common.Categories,
        PearlWelcome: 'welcome to pearl!',
        SSOSlogan: 'One Account. Three Brands.',
        InfoVerified: 'your information has been verified',
        InvalidVerificationCode: 'Invalid or expired code',
        ResendCodeFailure: 'Could not resend code at this time',
        BuildProfileText: "let's get started by building your vendor profile",
        GoogleLogo: 'google logo on white',
        PoweredByGoogleLogoPrefix: 'Powered by',
        ComingSoon: 'coming soon',
        ComingSoonTooltip: 'stay tuned, we will have this live soon',
        GetStarted: "let's get started",
        DefaultProfilePictureAlt: 'ProfilePicture',
        Preview: 'preview',
        Edit: common.Actions.Edit,
        EditBack: 'back',
        Next: 'Next',
        StartTutorial: 'Start Tutorial',
        SkipTutorial: 'Skip Tutorial',
        EndTutorial: 'End Tutorial',
        SubscriptionUpdated: 'Subscription Updated',
        NoInformationProvided: framework.NoInformationProvided,
        EmbeddedVideoNotSupported: 'Sorry, your browser does not support embedded videos.',
        UnsavedPrimary: 'You have unsaved changes',
        UnsavedSecondary: 'Without saving, any previous work will be lost.',
        UnsavedBtnPrimary: 'Save',
        UnsavedBtnSecondary: 'Discard Changes',
        ApiLoaderLogo: `${BRAND} Bridal logo`,
        Dashboard: {
            HeroEditModal: {
                AltText: 'hero option',
            },
        },
        EditWebstore: 'edit pearl page',
        VisitWebstore: 'visit pearl page',
        DavidsBridalLogoAlt: "David's Bridal Logo",
        AccountManagementDefaultPageTitle: 'hello',
        AccountManagement: {
            PageGreeting: 'hello',
            HeaderTitle: 'my account',
        },
        Error: {
            title: 'an error has occurred',
            subtitle: 'please navigate back to your webstore to try again',
            code: '¯\\_(ツ)_/¯',
        },
        Forms: {
            EventUserSignup: {
                Title: 'create an account',
                ContactFirstNameLabel: 'first name',
                ContactLastNameLabel: 'last name',
                ContactEmailAddressLabel: common.Forms.Labels.EmailAddress,
                ContactPhoneNumberLabel: 'phone number',
                PasswordRequirements: {
                    Header: 'Your password must contain at least:',
                    List: [
                        'one number',
                        'one special character',
                        'both uppercase and lowercase letters',
                    ],
                },
                SubmitAgreement: `By clicking “I Agree” you agree to participate in periodic surveys or questionnaires received at the information you have entered above and you further understand and agree that your interactions with ${BRAND} shall be governed by the <terms>Terms</terms> and <privacy>Privacy Policies</privacy> available therein.`,
                ...common.Forms.Signup,
            },
            Signup: {
                Title: 'create a business account',
                ContactFirstNameLabel: 'contact first name',
                ContactLastNameLabel: 'contact last name',
                ContactEmailAddressLabel: 'contact email address',
                ContactPhoneNumberLabel: 'contact phone number',
                SubmitAgreement:
                    'By clicking “I Agree”, you agree you have reviewed and agree that your access to and use of the Pearl platform shall be governed by the terms, conditions and representations contained in the <vendorAgreement>Vendor Agreement</vendorAgreement>.',
                ...common.Forms.Signup,
            },
            // TODO: make label input on ui kit to allow direction on label text
            UserBasics: {
                StepName: 'Event profile set up',
                StepTitle: "Let's start with some basics",
                StepSubtitle: '',
                FirstNameLabel: "What's your first name?",
                LastNameLabel: "What's your last name?",
                EventTypeLabel: 'Event Type',
                UserEventRoleLabel: "What's your role in this event?",
                GifAltText:
                    'Teal pen writing on blank peach-colored scroll with heart in upper left corner.',
                EventTypes: {
                    Option1: 'Wedding',
                    Option2: 'Prom',
                    Option3: 'First Communion',
                    Option4: 'Homecoming',
                    Option5: 'Graduation',
                    Option6: 'Group',
                    Option7: 'Quinceanera',
                    Option8: 'Special Occasion',
                    Option9: 'Military Ball',
                    Option10: 'Party Only',
                },
                UserEventRoles: {
                    Option1: 'Bride',
                    Option2: 'Bridesmaid',
                    Option3: 'Mother of the Bride/Groom',
                    Option4: 'Grandmother of the Bride/Groom',
                    Option5: 'Party Goer/other',
                    Option6: 'Prom/Homecoming',
                    Option7: 'Partner',
                    Option8: 'Quinceanera',
                },
            },
            EventDetails: {
                StepName: 'Event Size & Budget',
                StepTitle: 'What is the size of your event?',
                StepSubtitle: '',
                GuestCountLabel: 'Number of guests',
                NotSureLabel: 'Not quite sure yet',
                BudgetQuestion: "What's your budget?",
                MinGuestCountError: 'value entered should be greater than 0',
                InvalidCharactersError: 'value entered should be a number',
                BudgetTiers: {
                    Tier1: {
                        Title: 'budget-friendly',
                        Subtitle: "I'm looking to keep things as affordable as possible.",
                        MonetarySymbols: '$',
                    },
                    Tier2: {
                        Title: 'standard',
                        Subtitle: 'I can spend about what is average in the area.',
                        MonetarySymbols: '$$',
                    },
                    Tier3: {
                        Title: 'premium',
                        Subtitle: "I'd like to consider higher-end offerings.",
                        MonetarySymbols: '$$$',
                    },
                    Tier4: {
                        Title: 'luxury',
                        Subtitle: 'I have a grand event in mind—and the funds to match.',
                        MonetarySymbols: '$$$$',
                    },
                },
            },
            EventDate: {
                StepName: 'event date',
                Title: 'when is your event?',
                Subtitle: '',
                FlexibleCheck: "We haven't decided yet",
                EventQuestion: 'What is your estimated timeline?',
                DropdownPlaceholder: 'select',
                Option1: 'Less than 6 months',
                Option2: '6-12 months',
                Option3: '12+ months',
            },
            EventVendors: {
                StepName: 'choose vendors',
                Title: 'which vendors are you looking for?',
                Subtitle: '',
                LookingForEverythingCheckbox: 'looking for everything',
                Edit: {
                    VendorRemovalConfirmation:
                        'are you sure you want to remove details for {{vendorName}}?',
                    VendorDetailWarning: `You won't be able to undo this change`,
                    CategoryRemovalConfirmation:
                        'Are you sure you want to remove categories: {{catNames}}?',
                    VendorDetailsWarning: 'All vendor details will be removed',
                    CategoryDetailWarning: 'You will be unable to undo this item',
                    RemoveButtonLabel: 'REMOVE',
                    CancelButtonLabel: 'CANCEL',
                    DeleteCategoryButtonLabel: 'DELETE CATEGORY',
                },
            },
            EventLocation: {
                StepName: 'event location',
                StepTitle: 'Where is your event?',
                StepSubtitle: '',
                EventLocationInputLabel: 'Enter location',
                LocationStepPlaceholder: 'city, state',
                EventLocationUnknown: "We haven't chosen a location yet",
                EventLocationLabel: 'In which city & state will your event take place?',
                EventLocationTypeLabel: 'What type of {{eventType}} are you having?',
                EventLocationType: {
                    Option1: 'local',
                    Option2: 'destination',
                    Option3: "I don't know",
                },
            },
            VendorRecommendations: {
                StepName: 'search preferences',
                StepTitle: 'How would you like to find your vendors?',
                StepSubtitle:
                    'Let us help you narrow down that list! Here are a couple ways that we can help you sift through the masses.',
                Preferences: {
                    Option1: 'I want to browse vendors myself',
                    Option2: 'Send me some recommendations, please',
                },
            },
            ContactAndInquire: {
                UserQuestionLabel: 'message to vendor',
                UserQuestionPlaceholder: 'Write message here',
                PriceTierLabel: 'budget',
                UserNameLabel: 'name',
                ContactEventDateLabel: 'event date',
                ConfirmDetails: 'confirm your details',
                SubmitInquiry: 'submit your inquiry',
                EventTypeLabel: 'event type',
            },
            InquirySubmitted: {
                InquirySubmitted: 'your inquiry has been submitted!',
            },
            AddVendor: {
                Subtitle: 'add vendor details',
                BusinessNameLabel: 'Business Name',
                BusinessDescriptionLabel: 'Business Description',
                BusinessDescriptionPlaceholder: 'Add a description',
                PhoneNumberLabel: 'Phone Number',
                EmailAddressLabel: 'Email Address',
                WebsiteLabel: 'Website URL',
                SaveVendor: 'save',
                RemoveVendor: 'remove vendor',
            },
            Labels: common.Forms.Labels,
            Validation: common.Forms.Validation,
            Placeholders: common.Forms.Placeholders,
            RequiredFieldsNote: common.Forms.RequiredFieldsNote,
            Names: common.Forms.Names,
            Account: {
                UserNameLabel: common.Forms.Labels.UserName,
                UpdateUsername: 'update username',
                UpdatePassword: common.Forms.Labels.UpdatePassword,
                AddNewLabel: common.Forms.Labels.AddNew,
                NewPassword: common.Forms.Labels.NewPassword,
                ConfirmPassword: 'confirm new password',
                UserEmailLabel: 'enter your email',
                ResetCodeLabel: 'enter reset code',
                NewPasswordLabel: 'new password',
                CheckEmailForCode:
                    'A password reset code has been sent to <strong>{{UserName}}</strong>',
            },
            EventInformation: {
                Title: 'event information',
                EventDateTitle: 'date',
                EventRoleTitle: 'my role',
                EventLocationTitle: 'location',
                EventGuestTitle: 'guest count',
                EventBudgetTitle: 'budget',
                EventBudgetCheckbox: "I don't know yet",
                EventDateLabel: 'event date',
                EventDateCheckbox: "I don't know my date yet",
                EventTimelineLabel: 'event timeline',
                EventTypeLabel: 'event type',
                EventRoleLabel: 'event role',
                EventLocationInputLabel: 'enter location',
                EventLocationCheckbox: "I don't know the location yet",
                EventLocationTypeLabel: 'location type',
                GuestCountLabel: 'number of guests',
                EventGuestCheckbox: "I don't know yet",
                FirstNameLabel: 'first name',
                LastNameLabel: 'last name',
            },
            EventAccountInfo: {
                Title: 'account information',
                ClickHere: common.Forms.Labels.ClickHere,
                FistName: common.Forms.Labels.FirstName,
                LastName: common.Forms.Labels.LastName,
                PhoneNumber: common.Forms.Labels.PhoneNumber,
                EmailAddress: common.Forms.Labels.EmailAddress,
                Birthday: common.Forms.Labels.Birthday,
                UpdatePassword: common.Forms.Labels.UpdatePassword,
                NewPassword: common.Forms.Labels.NewPassword,
                CurrentPassword: common.Forms.Labels.CurrentPassword,
                ReEnterPassword: common.Forms.Labels.ReEnterPassword,
                PasswordSectionTitle: 'account/password',
            },
            Buttons: {
                SignupSubmitButton: 'Sign Up',
                SignUpEmailButton: 'Sign Up with Email',
                SignUpGoogleButton: 'Sign Up with Google',
                SignInGoogleButton: 'Sign In with Google',
                ForgotPasswordButton: 'Forgot Password',
                MemberLoginButton: 'Already a member? Log in',
                LoginButton: 'Log In',
                SubmitButton: 'Submit',
                BackToSignUpButton: 'Back to Sign Up',
                ResendCodeButton: 'Resend Code',
                CompleteWebstore: 'Complete',
                BrowseFiles: 'Browse Files',
                Save: 'Save',
                AutoSaved: 'AutoSaved',
                Clear: 'Clear',
            },
            EditBasics: {
                BusinessNameLabel: 'business name',
                BusinessLocationLabel: 'business location',
                BusinessSummaryLabel: 'business description',
                BusinessAddressFormation: 'enter a comma separate street address, city, state',
            },
            EditAbout: {
                BusinessSummaryPlaceholder: 'start typing',
                ServiceAddressLabel: 'your service location',
            },
            BusinessSummary: {
                Title: 'what else do you want customers to know about your business?',
                Subtitle:
                    "Share your story so they can get to know you a little better and gauge whether you'd work well together.",
                StepName: 'Business Description',
                BusinessSummaryPlaceholder: common.Forms.Placeholders.StartTyping,
                BusinessSummaryLabel: 'business description',
                GifAltText:
                    'Envelope opening and letter with a heart being removed from the envelope.',
                EditSectionDescription:
                    'Tell customers what they need to know about your business.',
            },
            BusinessDetails: {
                StepName: 'The Basics',
                WebstoreNameLabel: common.Forms.Labels.WebstoreName,
                WebstoreUrlLabel: common.Forms.Labels.PearlWebstoreUrl,
                VendorCategoryIdLabel: common.Forms.Labels.VendorCategory,
                VendorSubCategoryIdsLabel: common.Forms.Labels.VendorSubCategory,
                Title: 'tell us about your business!',
                Subtitle:
                    'These essential details help customers find you when they start searching for vendors.',
                GifAltText:
                    'Store front with sun shining on windows that have baskets of peach, orange, and maroon colored flowers.',
            },
            UserLogin: {
                UserEmailLabel: 'email',
                UserEmailPlaceholder: 'enter your email',
                UserPasswordLabel: 'password',
                UserPasswordPlaceholder: 'enter your password',
            },
            ForgotPassword: {
                UserEmailLabel: 'enter your email',
                ResetCodeLabel: 'enter reset code',
                NewPasswordLabel: 'new password',
                CheckEmailForCode:
                    'check <strong>{{UserEmail}}</strong> for your password reset code',
            },
            Verification: {
                CheckEmail: 'verify your email',
                CheckEmailForCode: 'check <1>{{email}}</1> for your verification code',
                VerificationCodeLabel: 'enter verification code',
            },
            BusinessAddress: {
                StepName: 'Business Address',
                Title: 'what’s your business address?',
                Subtitle:
                    'This is for verification purposes only and will not be displayed on your webstore.',
                AddressLine1: 'street address line 1',
                AddressLine2: 'street address line 2',
                AddressCity: 'city',
                AddressState: 'state',
                AddressZipcode: 'zipcode',
                GifAltText: 'Maroon location marker with heart on top of an empty map.',
            },
            ServiceArea: {
                StepName: 'Service Area',
                Title: 'how do you reach your customers?',
                ServiceTypeQuestion: 'how do you serve your customers?',
                Subtitle:
                    "Share how far you're willing to travel by indicating if you’re up for a longer trip or prefer to stay local.",
                ServiceSelection: 'how do you serve your customers?',
                Online: 'Online',
                Store: 'At my location',
                StoreSubtitle:
                    'Customers can visit your business in person ie: dress shops, venues, hotels',
                Travel: 'Travel to event location',
                TravelSubtitle:
                    'Your business makes visits to customers ie: caterer, dj, photographer',
                ShippingSelection: 'where do you ship?',
                ServiceAddress: 'what is the address of your local store?',
                ServiceAddressSame: 'Same as business address',
                ServiceAddressDifferent: 'Use a different address',
                DisplayToggle: 'show my business location',
                DisplayToggleSubtitle:
                    'The address of your business location will be publicly displayed on your Pearl Page.',
                TravelSelection: 'how far are you willing to travel?',
                TravelSelectionSubtitle:
                    'Does your business travel to specific locations? Define your service area to appear in more relevant searches.',
                AdditionalLocationsTip:
                    'Premium members will be able to add additional locations on the vendor pearl page.',
                Local: 'locally',
                ServiceAddressLabel: 'your store location',
                National: 'nationally',
                Global: 'globally',
                TravelDistanceLabel: 'select',
                EditSectionTitle: 'service area',
                EditSectionDescription:
                    "Share how far you're willing to travel by indicating if you prefer to stay local or are up for a longer trip.",
                ServiceAreaLabel: 'What city & state do you service?',
            },
            AmenitiesServices: {
                StepName: 'Services & Amenities',
                Title: 'what services do you offer?',
                Subtitle:
                    'Describe all that you can provide for the big day and beyond! Check all that apply.',
                GifAltText:
                    'Teal pen writing on blank peach-colored scroll with heart in upper left corner.',
            },
            VendorCharacteristics: {
                StepName: 'Service Vibes',
                Title: 'what characteristics describe your services?',
                Subtitle:
                    "When it comes to brides and wedding vendors, it's all about finding a good match.",
                GifAltText:
                    'Teal pen writing on blank peach-colored scroll with heart in upper left corner.',
                AccordionName: 'Style Characteristics',
                AccordionSubLabel:
                    '*note this information is used in customer search and not shown on webstore',
            },
            SocialLinks: {
                StepName: 'Social Media',
                Title: 'where can customers go to learn more about your business?',
                Subtitle:
                    "Because we all know they're gonna look you up, let's make it easy for them to find you.",
                Requirement: 'Add Social Media',
                GifAltText:
                    'Three bridesmaids wearing different shades of plum dresses each raising their floral bouquets.',
                EditSectionDescription:
                    'Let customers know where else to find information about your business.',
                ...framework.SocialLinks,
            },
            PriceTier: {
                Title: 'how much do your services cost?',
                StepName: 'Pricing',
                Subtitle:
                    "Let's get real about money to ensure there are no big surprises when you connect with potential customers. Pick a tier based on how you compare to other businesses nearby.",
                PriceTier: 'PRICE TIER',
                BoxBody: {
                    BudgetFriendly: 'You can work with most big-day budgets.',
                    Standard: 'Your prices are pretty typical for the area.',
                    Premium: 'Your offerings come in at the higher end.',
                    Luxury: 'You cater to the grandest events.',
                },
                BudgetFriendly: framework.WebstoreSections.PriceTier.BudgetFriendly,
                Standard: framework.WebstoreSections.PriceTier.Standard,
                Premium: framework.WebstoreSections.PriceTier.Premium,
                Luxury: framework.WebstoreSections.PriceTier.Luxury,
                GifAltText: 'Teal calculator with dollar signs being displayed across the screen.',
                MonetarySymbols: {
                    BudgetFriendly: '$',
                    Standard: '$$',
                    Premium: '$$$',
                    Luxury: '$$$$',
                },
                EditSectionDescription:
                    'Pick a tier based on how you compare to other businesses nearby.',
            },
            Capacity: {
                MinCapacityError: 'value entered should be greater than 0',
                InvalidCharactersError: 'value entered should be a number',
            },
            VenueCapacity: {
                Title: 'what is your max guest capacity?',
                StepName: 'Capacity',
                Subtitle: 'Let customers know how many guests can fit in your space.',
                EditSectionDescription: 'Let customers know how many guests can fit in your space.',
                MaxGuestCapacity: 'max guest capacity',
            },
            LodgingCapacity: {
                Title: 'what is your max room block capacity?',
                StepName: 'Capacity',
                Subtitle: 'Let customers know how many rooms you have available',
                EditSectionDescription: 'Let customers know how many rooms you have available',
                MaxGuestCapacity: 'max rooms',
            },
            MediaEdit: {
                CoverPhoto: 'Cover Photo',
                Back: 'Back to all photos',
                Title: 'Edit photos',
                CoverPhotoSectionHeader: 'Cover Photo',
                MakeCover: 'Make this my cover photo',
                CaptionSectionHeader: 'Caption',
                captionLabel: '',
                captionPlaceholder: 'image caption',
                SaveButtonText: 'Save',
                RemoveButtonText: 'Remove',
            },
            MediaUpload: {
                Title: 'hit us with your best shot!',
                StepName: 'Photos',
                Subtitle: 'Add pictures to give customers an idea of what they can expect.',
                AddMedia: 'Add Photos',
                MinMax: 'Maximum of 25 - max file size upload is 15 MB',
                NumUploads: '{{uploads}} of {{max}} images uploaded',
                NoImages: 'You must upload at least three images to continue',
                ExceedsMaxImages: 'Number of images cannot exceed 25',
                ExceedsMaxUploadCount: 'Number of images cannot exceed {{uploadLimit}}',
                ExceedsMaxSize:
                    'The image you are trying to upload exceeds the 15 MB attachment limit: {{filename}}',
                UnsupportedFileType: 'Image type not supported by Pearl: {{filename}}',
                GifAltText:
                    'Front of mauve-colored camera tilting while snapping a picture with the camera light flashing.',
                WaitForUploadToFinish: 'Please wait for the current upload to finish.',
                UploadSuccess: 'All updates have been successfully saved!',
                UploadError: 'An error occurred while uploading images',
                DeleteSuccess: 'Image successfully deleted!',
                LegalTitle: 'By submitting and/or posting any User Content to the Website: ',
                LegalBeforeLink:
                    ' You grant to David’s Bridal, LLC a license as otherwise set forth in the ',
                LegalTerms: 'Terms and Conditions;',
                LegalAfterLink:
                    ' You represent and warrant that You have and/or own all necessary consents to post the User ' +
                    'Content; and You agree to fully indemnify David’s Bridal, LLC for any claims related to the submission or ' +
                    'posting of Your User Content.',
            },
            Packages: {
                Title: 'package',
                Error: 'please complete the required fields or remove before saving',
                AddItem: 'Add Item',
                NameLabel: 'package name',
                PriceLabel: 'package price',
                DescriptionLabel: 'package description',
                InclusionsLabel: 'package inclusions (bulleted list recommended)',
                InclusionsLabelComment: '(looks best as a bulleted list)',
                RemoveButtonText: 'Remove',
            },
            Partners: {
                Title: 'business besties',
                Error: 'please complete the required fields or remove before saving',
                AddItem: 'Add Item',
                Subtitle: 'business bestie',
                DescriptionLabel: 'why we love this bestie',
                SearchParamLabel: 'search by pearl page name (enter at least 4 characters)',
            },
            Faqs: {
                Title: 'frequently asked question',
                Error: 'please complete the required fields or remove before saving',
                AddItem: 'Add FAQ',
                QuestionLabel: 'select a question',
                CustomQuestionLabel: 'what is your question?',
                AnswerLabel: 'your answer',
                RemoveButtonText: 'Remove',
            },
            Awards: {
                AddItem: 'Add Item',
                DefaultName: 'award',
                NameLabel: 'award name',
                LinkLabel: 'award link',
                RemoveButtonText: 'Remove',
                Error: 'please complete the required fields or remove award before saving',
            },
            Subtitle: {
                Header: 'start here',
                LoginModal: 'Welcome! Are you a',
                Bride: 'BRIDE/GROOM/OTHER',
                Vendor: 'VENDOR',
            },

            CYBContactCS: {
                AdditionalInfoLabel: 'additional information',
            },
        },
        Pages: {
            VendorLandingPage: {
                Title: 'All things planning.<break />All in one app.',
                HeroSubtitle:
                    'Pearl plans so you can party, with all the genius tools you need for the event of a lifetime.',
                Subtitle: 'start planning',
                TopVendors: {
                    Title: 'top wedding vendor categories',
                    AddLocation: 'add location',
                    ViewAll: 'view all',
                    BecomeAVendor: 'become a vendor',
                },
                PlanningToolkit: {
                    Title: 'planning toolkit',
                    Description:
                        'We offer free wedding planning tools to help streamline your wedding journey. Create a wedding vision board, build a personalized wedding website, and shop top retailers with our one-stop registry.',
                    LearnMore: 'learn more',
                    Checklist: {
                        Title: 'checklist',
                        AbbreviatedContent:
                            'Manage and organize every major to-do for your big day.',
                        FullContent:
                            'Our customizable checklist keeps track of every to-do with helpful tips and tricks that make planning a total breeze.',
                        LinkText: 'create my checklist',
                        LoggedInLinkText: 'create/manage my checklist',
                        ImgAlt: 'wedding checklist displayed on tablet screen',
                    },
                    Website: {
                        Title: 'website',
                        AbbreviatedContent:
                            'Build an elegant website to showcase your upcoming wedding and related events.',
                        FullContent:
                            'Create your custom wedding website with Blueprint. Choose from hundreds of gorgeous templates to collect RSVPs, share your registry, and send need-to-know details to your guests.',
                        LinkText: 'create my website',
                        ImgAlt: 'wedding website displayed on laptop screen',
                    },
                    Registry: {
                        Title: 'registry',
                        AbbreviatedContent:
                            'Shop top retailers room-by-room, add gifts from any website, and much more.',
                        FullContent:
                            "It's easier than ever for guests to find your perfect gift with our registry by Blueprint. Add gifts from any site and even link other registries, all in one place.",
                        LinkText: 'create my registry',
                        ImgAlt: 'teal KitchenAid mixer',
                    },
                    VisionBoard: {
                        Title: 'vision board',
                        AbbreviatedContent:
                            'Take your quiz to create a vision board for your dream wedding.',
                        FullContent:
                            'Our vision board is a personalized quiz to help you pick the perfect palette, theme and feel for your big day. Also known as a mood board, this handy tool keeps you inspired for your big day.',
                        LinkText: 'start planning',
                        ImgAlt: 'vision board displayed on cell phone screen',
                    },
                },
                PartnerCarousel: {
                    Makeup: {
                        Title: 'makeup mishaps',
                        Subtitle: 'no more',
                        TextContent:
                            'Stay flawless all night long with the Mally Beauty x David’s Bridal Wedding Day Touch-Up Kit—a handy set of full sized makeup products designed to keep you photo-ready on your big day.',
                        LinkText: 'shop the kit',
                    },
                    Diamonds: {
                        Title: 'This rock',
                        Subtitle: 'flawless',
                        TextContent:
                            'Introducing lab-created diamond simulant jewelry made with elite craftsmanship and set in precious metal—in other words, all the sparkle of a diamond for a fraction of the price.',
                        LinkText: 'learn more',
                    },
                    Tuxedos: {
                        Title: 'Dashing looks',
                        Subtitle: 'for boys and men',
                        TextContent:
                            'Let us formally introduce you to occasionwear well-suited to every size.',
                        LinkText: 'learn more',
                    },
                    BecomeVendor: {
                        Subtitle: 'become a vendor!',
                        TextContent:
                            'Join the fastest-growing wedding community and boost your business',
                        LinkText: 'start here',
                        ImgAlt: 'Greyscale close up of a party table with champagne, glasses, and trumpet.',
                    },
                },
                Faqs: {
                    Title: 'faqs',
                    Subtitle: 'Explore answers to commonly asked questions.',
                    ViewMore: 'View More',
                    QuestionAnswerData: {
                        Membership: {
                            Question: 'How can I make the most of my membership?',
                            Answer: "<li>Stand out with a wow-worthy webstore featuring your best images and all the important details. Use optional sections to expand your presence.</li><li>Join our private Facebook group, <facebookGroupLink>Pearl Vendor Resources</facebookGroupLink>, to connect with other vendors.</li><li>Start reaching even more couples by sharing on social media that you are a proud member of Pearl<emDash />tag <twitterLink>@pearlbydavids</twitterLink> and use <twitterHashtag>#pearlbydavids</twitterHashtag>.</li><li>Don't forgot to tag us in your Instagram stories, too<emDash />this is the best way to get featured on our social media!</li>",
                        },
                        Refer: {
                            Question: 'How can I refer a friend?',
                            Answer: 'The more, the merrier! Tell all your vendor BFFs to sign up by visiting the <vendorHomepage>Vendor Homepage</vendorHomepage>.',
                        },
                        Tiers: {
                            Question: "What's included in each subscription tier?",
                            Answer: 'So many great things! Check out our <subscriptionTiersLink>Subscription Tiers</subscriptionTiersLink> to see everything included in a Starter, Essential, and Premium membership.',
                        },
                        Upgrade: {
                            Question: 'How do I upgrade to an Essential or Premium subscription?',
                            Answer: 'Upgrade by going to your <accountSettingsLink>Account Settings</accountSettingsLink> and selecting <subscriptionsLink>Subscriptions</subscriptionsLink> at the left sidebar.',
                        },
                        Fees: {
                            Question: 'Are there any hidden fees I should know about?',
                            Answer: 'Nope! There are no hidden fees and you can cancel anytime.',
                        },
                    },
                },
                BecomeVendorCarousel: {
                    Become: {
                        Subtitle: 'become a vendor!',
                        TextContent:
                            'Join the fastest-growing wedding community and boost your business',
                        LinkText: 'start here',
                    },
                },
            },
            WebstoreDetails: {
                AboutSection: {
                    MarketsServed: 'markets served',
                    TravelRadius: 'travel radius',
                    PriceTier: 'price tier',
                    ReadMore: '...Read More',
                    ShowLess: 'Show Less',
                    Miles: 'miles',
                },
                MediaSection: {
                    NoMediaUploaded: 'You have not uploaded any photos yet',
                    SeeAllMediaButtonLabel: 'See All Photos',
                    SeeLessButtonLabel: 'See Less',
                },
            },
            VendorListing: {
                EndResult: 'End of Result',
                NoResults: {
                    Heading:
                        "You've reached an area where we are still adding vendors to help you with your dream wedding.",
                    Text: 'Try removing some selections or resetting your filters (if applicable).',
                },
                BlackTuxSection: {
                    BlackTuxImageAlt: 'The Black Tux Banner',
                    BlackTuxTitle: 'The Black Tux',
                    BlackTuxSubtitle:
                        "Find styles well-suited for the occasion with fits available for rent and for keeps. Get $200 off your order (that's a free rental!) when you reserve 6 additional wedding party looks of $175+",
                    BlackTuxLink: 'find his look',
                },
            },
            VendorDetailsPage: {
                ClaimYourBusiness: 'Claim Your Business',
                CYBFooterTitle: 'is this your business?',
                CYBFooterSubtitle:
                    'Own or manage this location? Claim this location for free to update information on this page and start receiving leads interested in your services.',
                CYBFooterButton: 'Claim This Location',
            },

            UserHomePageTitle: 'sign up for free',
            UserHomePageSubtitle: 'Create a free account and start browsing',
            HomePageTitle: 'show off your business with Pearl',
            HomePageSubtitle:
                'Create a free account so potential customers can see all that you offer when searching for vendors.',
            VendorLoginTitle: 'log in to pearl',
            VendorLoginInvalidUsernameOrPassword: 'invalid username or password',
            VendorForgotPasswordTitle: 'forgot password',
            VendorForgotPasswordBackToLogin: 'back to login',
            VendorForgotPasswordResetPassword: 'reset password',
            VendorForgotPasswordResetPasswordConfirmed:
                'A password reset code has been sent to your email.',
            VendorPasswordResetSuccess: 'Your password was successfully reset.',
            EditPages: {
                PackagesPageTitle: framework.WebstoreSections.Packages.Title,
                PartnersPageTitle: framework.WebstoreSections.PreferredPartners.Title,
                FaqsPageTitle: framework.WebstoreSections.Faqs.Title,
                AwardsPageTitle: framework.WebstoreSections.Awards.Title,
            },
            CustomerDashboard: {
                Welcome: 'hi {{firstName}}!',
                NoDate: 'event date not set',
                DaysLeft: ' days to go',
                HeroEdit: {
                    Title: 'upload image',
                },
                Sections: {
                    CompleteProfile: {
                        Title: 'find the best vendor for you',
                        Subtitle:
                            'Complete your profile so we can personalize vendor recommendation. (Only takes 5 min.)',
                        Button: 'COMPLETE PROFILE',
                    },
                    MyVendors: {
                        Title: 'my vendors',
                        CompletedOf: '{{completed}} of {{total}} booked',
                        NoCategories:
                            "It looks like you haven't booked any vendors yet! Add what kind of vendors you need.",
                        AddCategory: 'Add Category',
                    },
                    AddCategory: {
                        Title: 'add category',
                        Subtitle: 'add additional vendors to your list',
                        Button: 'ADD',
                    },
                    ExploreVendors: {
                        Title: 'explore {{category}}',
                        ViewAll: 'view all',
                    },
                    PlanningTools: {
                        Title: "pearl's event planning toolkit",
                    },
                },
            },
            Dashboard: {
                Welcome: 'hi {{webstoreName}}',
                HeroEdit: {
                    Title: 'upload image',
                },
                Sections: {
                    PearlPage: {
                        Title: 'pearl page',
                        Membership: 'membership',
                        Tip: 'tip',
                        StarterTip:
                            'Brides love to be informed! Add customer reviews and faqs to your page.',
                        EssentialTip:
                            'Get premier placement within search results—it will be sure to give you more clicks.',
                        PremiumTip:
                            'Wish to speak with potential clients directly? Want placement in David’s stores?',
                        UpgradeMembership: 'upgrade membership',
                        ChatWithSales: 'chat with sales',
                        EditPage: 'edit pearl page',
                        SectionsCompleted: 'sections completed',
                    },
                    InboxHighlights: {
                        Title: 'inbox',
                        UnreadMessage: 'total unread messages',
                        NewInquiries: 'new inquiries received',
                        GoToInbox: 'go to inbox',
                    },
                    Besties: {
                        Title: 'business besties',
                        Delete: common.Actions.Delete,
                        HorizontalMenuAriaLabel: 'open delete bestie menu',
                        DeleteBestieAriaLabel: 'delete bestie',
                        GotoBestiesEdit: 'go to business besties',
                        AddBestie: 'add to my bestie list',
                        MyBesties: 'besties vouch for me',
                        NewBestieCount: 'my <highlight>{{bestieCount}}</highlight> new besties',
                        EmptyBesties: 'No new besties',
                        EmptyBestie: 'No besties have vouched for you yet',
                    },
                    AnalyticHighlights: {
                        Title: 'analytic highlights',
                        AnalyticTimeframe: 'past 30 days',
                        PageVisits: 'pearl page visits',
                        PageVisitsDescription:
                            'Check out how many users are looking at your Pearl Page.',
                        InquiriesReceived: 'inquiries received',
                        InquiriesReceivedDescription:
                            'See who’s interested in your services for their upcoming event.',
                        SearchAppearances: 'search appearances',
                        SearchAppearancesDescription:
                            'Discover how often you’re appearing in search results.',
                        ViewAnalytics: 'view analytics',
                    },
                },
            },
            UnauthedDashboard: {
                LoginInstructions: 'Log in to view your dashboard',
                InstructionsSubtext: 'Once you log in, you’ll find your vendors here.',
                GifAltText:
                    'Store front with sun shining on windows that have baskets of peach, orange, and maroon colored flowers.',
            },
            UnauthedInbox: {
                LoginInstructions: 'Log in to view your inbox',
                InstructionsSubtext:
                    'Once you log in, you’ll find messages from your vendors here.',
                GifAltText:
                    'Teal pen writing on blank peach-colored scroll with heart in upper left corner.',
            },
            Login: {
                NoAccount: "Don't have an account?",
                Signup: 'Sign up',
                AreYouAVendor: 'Are you a vendor?',
            },
            Analytics: {
                Title: 'analytics',
                WebstoreVisitsTitle: 'webstore visits',
                AsOf: 'as of',
                AsOfWeek: 'as of Week',
                Search: {
                    Title: 'search',
                    Monthly: 'past month',
                    Weekly: 'past 7 days',
                    Quarterly: 'past quarter',
                    TotalSearchInCategory: 'total searches in my category',
                    TotalSearchAppearances: 'total searches I appeared in ',
                    TotalVendorsInArea: 'total vendors in my area',
                    TotalVendorsInCategoryAndArea: 'total vendors in my category in my area',
                },
                Inquiries: {
                    Title: 'inquiries',
                    Description: '% of inquiries received compared to webstore views',
                    NewInquiries: 'new inquiries',
                    PastWeek: 'past 7 days',
                    PastMonth: 'past month',
                    PastQuarter: 'past quarter',
                },
                Modal: {
                    Title: 'want to see your stats?',
                    Subtitle:
                        'Your current membership level does not allow you to view insights of your webstore. Upgrade your membership to gain access to your numbers.',
                    Upgrade: 'Upgrade membership',
                },
            },
            Favorites: {
                Title: 'my favorite vendors',
                Subtitle:
                    'Change your mind? Just click on the<icon />to remove the vendor from your list.',
                MoreLink: 'find more',
                EmptyFavoritesSubtitle:
                    'To easily access your favorite vendors, you can mark them as favorite by clicking on <icon />',
                EmptyFavoritesFindVendors: 'Find Vendors',
            },
            VendorAccountSettings: {
                PageTitle: 'settings',
                InboxSettingsTitle: 'inbox settings',
                SendNew: 'Send email upon each new message',
                SendDailyDigest:
                    'Send daily digest email (one email for all messages in past 24 hours)',
            },
            VendorAccountInformation: {
                PageTitle: 'account information',
            },
            VendorSubscription: {
                PageTitle: 'plan details',
                Tier: 'Current Plan',
                Quantity: 'Pearl Pages (seats)',
                Interval: 'Payment Frequency',
                Intervals: {
                    Year: 'Annually',
                    Month: 'Monthly',
                },
                Amount: 'Renewal Amount',
                Expiration: 'Payment Due Date',
                Upgrade: 'change plan',
            },
            VendorBusinessLocations: {
                PageTitle: 'business locations',
                Subtitle:
                    'Manage multiple businesses by either manually inputting the location or importing a spreadsheet.',
                AddLocationButton: 'add a location',
                AddWebstoreModal: {
                    Title: 'add a pearl page',
                    Subtitle: 'Choose how you want to enter your business details.',
                    NoOptionSelectedError: 'Please select an option below.',
                    AddManual: {
                        Title: 'add manually',
                        Subtext:
                            'Fill out a form for each business you want to add. Recommended if you only want to add a few stores.',
                    },
                    AddMultiple: {
                        Title: 'add multiple stores',
                        Subtext:
                            'Use a spreadsheet to add multiple stores at once. Recommended for more than # stores.',
                    },
                    LearnMore: 'Learn more',
                },
                AddMultipleLocationsModals: {
                    Title: 'add multiple locations',
                    Subtitle:
                        'Our customer service team would be happy to help you add your locations to Pearl. This service is only available when adding 10 or more locations.',
                    BeforeCell: 'To get started please call ',
                    InbetweenText: ' or email ',
                },
                FilterPlaceholder: 'filter by name, location, membership, or status',
                MultiSelectCount: 'pearl pages selected',
                SeatsUsed: '<usage>{{seatsUsed}}/{{seatsAvailable}}</usage> {{tierName}} used',
                PurchaseMore: 'purchase more',
                PurchaseSubscription: 'Subscribe',
                Columns: {
                    Id: 'id',
                    Active: 'active',
                    StoreName: 'store name',
                    Location: 'location',
                    TierName: 'membership',
                    PearlPageStatus: 'pearl page status',
                    Action: 'action',
                },
                Status: {
                    Published: 'Published',
                    HasChanges: 'Unpublished Changes',
                    Unpublished: 'Unpublished',
                },
                NameAnnotations: {
                    Default: 'Default',
                    Active: 'Active',
                },
                Actions: {
                    Copy: {
                        Progress: 'copying {{current}} of {{total}} webstores',
                        Error: 'error copying {{errorCount}} of {{total}} webstores: {{message}}',
                        Success: 'successfully copied {{total}} webstores',
                    },
                    Delete: {
                        Progress: 'deleting {{current}} of {{total}} webstores',
                        Error: 'error deleting {{errorCount}} of {{total}} webstores: {{message}}',
                        Success: 'successfully deleted {{total}} webstores',
                        ConfirmSingularNoPublished:
                            'are you sure you want to delete {{webstoreName}}?',
                        ConfirmPluralNoPublished:
                            'are you sure you want to delete these {{count}} stores?',
                        ConfirmSingularPublished:
                            'do you want to unpublish {{webstoreName}} instead?',
                        ConfirmPluralPublished:
                            'do you want to unpublish these {{count}} stores instead?',
                        ConfirmSubTextNoPublished:
                            'We recommend you leave stores unpublished instead. ' +
                            'This will ensure that no customers are able to view it but you will ' +
                            'still have all the content related to this pearl page.',
                        ConfirmSubTextPublished:
                            'We recommend you unpublish instead. This will ensure ' +
                            'that no customers are able to view it but you will still have all the ' +
                            'content related to this pearl page.',
                        PrimaryAction: 'unpublish pearl page',
                        AlternateAction: 'delete pearl page',
                    },
                    Publish: {
                        Progress: 'publishing {{current}} of {{total}} webstores',
                        Error: 'error publishing {{errorCount}} of {{total}} webstores: {{message}}',
                        Success: 'successfully published {{total}} webstores',
                        UnknownError: 'unknown error',
                    },
                    Unpublish: {
                        Progress: 'unpublishing {{current}} of {{total}} webstores',
                        Error: 'error unpublishing {{errorCount}} of {{total}} webstores: {{message}}',
                        Success: 'successfully unpublished {{total}} webstores',
                        ConfirmSingular: 'are you sure you want to unpublish {{webstoreName}}?',
                        ConfirmPlural: 'are you sure you want to unpublish these {{count}} stores?',
                        ConfirmSubText:
                            'Unpublishing a page will remove the page from the ' +
                            'site but it will remain in your table of business locations to ' +
                            'be edited and published at a later date.',
                        PrimaryAction: 'unpublish pearl page',
                        AlternateAction: 'cancel',
                    },
                    AssignSeat: {
                        Error: 'error switching {{total}} webstores: {{message}}',
                        Success: 'successfully switched {{total}} webstores',
                        QuantityExceeded: 'subscription quantity exceeded',
                        SubscriptionNotFound: 'no subscription available',
                        UnknownError: 'unknown error',
                        OopsieWoopsie: 'Oops! It appears that you require additional seats.',
                        OopsieWoopsieSubTextAbbreviated:
                            'You could purchase more, or switch a different pearl page to Starter.',
                        OopsieWoopsieSubText:
                            'It looks like you attempted to upgrade {{count}} webstores, ' +
                            'but unfortunately, you only have {{seatsAvailable}} seats available. You could ' +
                            'purchase more, or simply deselect one and try upgrading again',
                        OopsieWoopsieAlternateAction: 'go back',
                    },
                    UnassignSeat: {
                        Error: 'error switching {{total}} webstores: {{message}}',
                        Success: 'successfully switched {{total}} webstores',
                    },
                },
            },
            ClaimHelpfulInformation: {
                Title: 'Claim ',
                Subtitle:
                    'Own or manage this location? Claim this location for free to update information on this page and start receiving leads interested in your services.',
                CYBButton: 'Claim This Location',
                ClaimMultiButton: 'Claim Multiple Locations',
                BusinessListedQuestion: 'how did my business get listed on pearl?',
                BusinessListedAnswer:
                    'To provide our users with the most complete and up-to-date information about wedding vendors, Pearl has pages automatically created for leading vendors. If you would like to have your listing removed please <contact>contact us</contact>.',
                HowPearlWorksTitle: 'how pearl works',
                HowPearlWorksSubtitle:
                    "Pearl by David's is the ultimate planning app, connecting local vendors with couples for the best event ever.",
                CreateTitle: 'create your pearl page',
                CreateSubtitle:
                    "Customize and publish your Pearl page in a matter of minutes—it's a cinch!",
                AccessTitle: 'access tons of brides',
                AccessSubtitle:
                    "As part of the David's Bridal family, we have brides' trust for the dress and all the rest.",
                CoupleTitle: 'couples come to you',
                CoupleSubtitle:
                    'Our genius search function helps nearlyweds find you through location, availability, style, and more!',
            },
            ClaimSendCodeVerify: {
                Title: 'verify your identity',
                Subtitle1:
                    'Click the button below to send a verification code to the email associated with this business ',
                Subtitle2:
                    'If you do not have access to this email address or are in need of further assistance, please <contact>contact customer service.</contact>',
                SendCodeButton: 'Send Verification Code',
            },
            ClaimVerifyYourId: {
                Title: 'verify your identity',
                SubtitleTop:
                    'A verification code has been sent to the email associated with this business ',
                SubtitleBottom:
                    "If you're experiencing issues with verification, or if you need further assistance and information, please <contact>contact customer service</contact>.",
                Agreement:
                    'I certify that I have read and agree to be bound to the <terms>Terms of Service</terms>, <privacy>Privacy Policy</privacy>, <arbitration>Arbitration Provision</arbitration> and <vendorAgreement>Vendor Agreement</vendorAgreement>.',
                Continue: 'Continue',
                CodeTitle: 'enter code',
                ResendCode: 'resend code',
                CodeError: 'Incorrect Code',
            },
            ClaimForms: {
                TitleContact: 'contact customer service',
                SubtitleContact:
                    'Fill out the form form below and our customer service team will be in touch. If you have any questions or concerns please call ',
                FormHeaderContact: 'how can we help?',
                TitleMultiple: 'claim multiple locations',
                SubtitleMultiple:
                    'Fill out the form form below and our customer service team will be in touch as they get the locations ready to be transferred over to you.',
                FormNameMultiple: 'name or business name',
                FormHeaderMultiple: 'locations',
                FormSubtitleMultiple: 'List out location names and addresses.',
                TitleAlready: 'business already claimed',
                SubtitleAlready:
                    'If you would like to claim the business as your own or if you will be managing this location please fill out the form form below explaining your role in the business. ',
                Subtitle2Already:
                    'Customer service will review your claim and be in touch with next steps.',
                FormNameAlready: 'business role',
                FormHeaderAlready: 'additional information',
                TitleRemove: 'remove my business',
                SubtitleRemove:
                    'Fill out the form form below and our customer service team will be in touch. Please note that this process can take up to 2 weeks.',
                FirstRemove: 'first name',
                LastRemove: 'last name',
                EmailRemove: 'email address',
                PhoneRemove: 'phone number',
                FormHeaderRemove: 'reason for removing',
                SubmitButton: 'Submit',
            },
            ClaimRequestReceived: {
                Title: 'request received',
                Subtitle1: 'Customer service will review your message and be in touch shortly.',
                Subtitle2: 'If you have any questions or concerns, please call ',
                HomepageButton: 'Return to Homepage',
            },
        },
        HelpInformation: {
            HelpInformationTitle: 'need help?',
            ContactDetails: 'contact our help center for all of your account needs',
            EmailLabel: 'vendor support',
            Email: 'pearlvendorsupport@davidsbridal.com',
            PhoneNumber: '1-888-369-8514',
            TextLabel: 'text PEARL to',
            TextNumber: '38201',
            TextBody: 'PEARL',
            FaqLabel: 'visit our FAQ',
            EventUserEmailLabel: 'email pearlsupport@davidsbridal.com',
            EventUserEmail: 'pearlsupport@davidsbridal.com',
            EventUserPhone: 'call',
            EventUserHelpInfoTitle: 'help',
            EventUserHelpCenter: 'help center',
        },
        WebstoreSetup: {
            ReturnToBusinessLocations: 'return to business locations',
        },
        WebstoreSections: {
            VendorBasics: {
                Title: 'location basics',
                Description: 'To edit this information, please visit your account information.',
                BasicsLink: {
                    Src: '/vendor/account-information',
                    DisplayText: 'account information',
                },
                Address: 'vendor address',
            },
            About: framework.WebstoreSections.About,
            AmenitiesServices: framework.WebstoreSections.AmenitiesServices,
            AvailabilityCalendar: framework.WebstoreSections.AvailabilityCalendar,
            Faqs: framework.WebstoreSections.Faqs,
            Awards: framework.WebstoreSections.Awards,
            Gallery: framework.WebstoreSections.Gallery,
            Media: framework.WebstoreSections.Media,
            Packages: framework.WebstoreSections.Packages,
            PreferredPartners: framework.WebstoreSections.PreferredPartners,
            PriceTier: framework.WebstoreSections.PriceTier,
            OptionalSectionTooltips: {
                NeedMoreInfo:
                    'You cannot currently enable this section without adding information.',
                Disable:
                    "Use this switch to disable this section. Don't worry your content will be saved.",
            },
            EmptySection: {
                NoInfoMessage: 'No information provided.',
                NoInfoInstructions: 'Click the edit button to add details.',
            },
            Reviews: {
                ConnectionText: 'Connected to {{webstoreName}}',
                DisconnectLink: 'Disconnect Account',
                DisconnectConfirmation: 'Are you sure you want to disconnect your account?',
                BackButton: 'back',
                ConnectPlaceholder: 'connect google account',
                DisconnectPrompt: 'Are you sure you want to disconnect your account?',
                Connected: 'Connected',
                ConnectAccountButton: 'Connect Account',
                DisconnectAccountButton: 'Disconnect Account',
                Discard: 'Discard',
                ...framework.WebstoreSections.Reviews,
            },
            Upgrade: {
                UpgradeText:
                    'You can try out this feature in edit mode. For full access to this feature, upgrade to our essential plan.',
                UpgradeButton: 'See Upgrade Options',
            },
        },
        Vendor: {
            AccountInformation: {
                SectionName: 'account information',
                SectionDescription:
                    'The fields in this section are for the parent account that manages all locations. You can manage individual location settings by selecting business locations in the left menu.',
                Basics: {
                    SectionName: 'vendor basics',
                    VendorName: 'parent company name',
                },
                Contact: {
                    SectionName: 'contact',
                    ContactFirstName: 'first name',
                    ContactLastName: 'last name',
                    ContactEmail: 'email address',
                    ContactPhone: 'phone number',
                },
                Address: {
                    SectionName: 'address',
                    Country: 'country/region',
                    Address1: 'street address',
                    Address2: 'street address line 2',
                    City: 'city',
                    State: 'state',
                    ZipCode: 'zipcode',
                },
            },
        },
        Navigation: {
            Back: 'Back',
            GlobalNavigationBar: {
                BecomeVendor: 'become a vendor',
                Vendors: 'vendors',
                Planning: 'planning',
                DavidsBridal: "David's Bridal",
                Pearl: BRAND,
                Diamond: "Diamond Loyalty Program by David's",
                DavidsLogoAlt: "David's Bridal logo",
                PearlLogoAlt: 'Pearl logo',
                DiamondLogoAlt: "Diamond by David's logo",
                Dashboard: 'Dashboard',
                Webstore: 'Pearl Page',
                Calendar: 'Calendar',
                WeddingWebsite: 'website',
                Registry: 'registry',
            },
            EventMobileFooterBar: {
                Vendors: 'vendors',
                Inbox: 'inbox',
                Dashboard: 'dashboard',
                Home: 'home',
                Planning: 'planning',
                Login: 'start',
                Account: 'account',
                Webstore: 'Pearl Page',
                Calendar: 'calendar',
            },
            HamburgerMenu: {
                OpenIconAriaLabel: 'open navigation menu',
                CloseIconAriaLabel: 'close navigation menu',
                DashboardDisplayName: 'dashboard',
                HomeDisplayName: 'home',
                AnalyticsDisplayName: 'analytics',
                InboxDisplayName: 'inbox',
                MessagesDisplayName: 'messages',
                NotificationsDisplayName: 'notifications',
                YourProfile: 'your profile',
                YourJourney: 'sign up to start planning your wedding.',
                HaveAnAccount: 'already have an account?',
                HiUser: 'hi {{firstName}}!',
                WebstoreDisplayName: 'pearl page',
                ViewDisplayName: 'view',
                CalendarDisplayName: 'calendar',
                VendorsDisplayName: 'vendors',
                FindAVendorDisplayName: 'find a vendor',
                PlanningToolsDisplayName: 'planning',
                VisionBoardDisplayName: 'vision board',
                ChecklistDisplayName: 'checklist',
                WeddingWebsiteDisplayName: 'wedding website',
                RegistryDisplayName: 'registry',
                QuizzesDisplayName: 'quizzes',
                BecomeVendorDisplayName: 'become a vendor',
                JoinTodayVendorDisplayName: 'join today',
                PricingDisplayName: 'pricing',
                HowItWorksDisplayName: 'how it works',
                HelpDisplayName: 'help',
                FaqsDisplayName: 'faqs',
                ContactUsDisplayName: 'contact us',
                WeddingDressFinderDisplayName: 'wedding dress quiz',
                BridesmaidDressFinderDisplayName: 'bridesmaid dress quiz',
                BlogDisplayName: 'ideas & advice',
            },
            Modals: {
                CloseIconLabel: 'close modal',
            },
            AriaLabel: framework.Navigation.AriaLabel,
            WebstoreNavigation: framework.Navigation.WebstoreNavigation,
            UserMenu: {
                AccountSettings: {
                    displayName: 'account settings',
                    ariaLabel: 'Select here to navigate to account settings',
                },
                CustomerView: {
                    displayName: 'switch to customer view',
                    ariaLabel: 'select here to navigate to customer view',
                },
                VendorView: {
                    displayName: 'switch to vendor view',
                    ariaLabel: 'select here to navigate to vendor view',
                },
                HelpCenter: {
                    displayName: 'help center',
                    ariaLabel: 'select here for help center support',
                },
                LogOut: {
                    displayName: 'log out',
                    ariaLabel: 'select here to log out',
                },
                AriaLabel: 'user menu',
                Greeting: 'hi',
            },
            AccountManagement: {
                NavigationDefaultAriaLabel: 'account management',
                NavigationTitle: 'account',
                HelpLinkDisplayLabel: 'help',
                AccountInfoLabel: 'account information',
                SubscriptionLinkLabel: 'billing',
                BillingHistoryLinkLabel: 'billing history',
                ContractsLinkLabel: 'contracts',
                SettingsLinkLabel: 'settings',
                WebstoreManagement: 'business locations',
            },
            EventAccountManagement: {
                FavoritesHeader: 'favorites',
                PlanningHeader: ' planning tools',
                FavoriteVendorsLabel: 'my favorite vendors',
                AccountInfoLabel: 'account information',
                CelebrantAccountLabel: 'celebrant account',
                EventInformationLabel: 'event information',
                SettingsLinkLabel: 'settings',
            },
            InboxIconAriaLabel: 'inbox',
        },
        Tutorial: {
            Webstore: {
                Welcome: {
                    header: "Let's get started!",
                    subtitle: 'Welcome to your Pearl vendor page editor. Let us show you around!',
                },
                WidgetEditor: {
                    header: 'section editor',
                    subtitle: 'Select the pencil icon to edit a section within your page',
                },
                AddSection: {
                    header: 'add section',
                    subtitle:
                        'By clicking on the toggle switch on and off, you can view/hide the sections on your webstore.',
                },
                Publish: {
                    header: 'publish',
                    subtitle:
                        'After you edit and preview updates click publish to allow customers to see changes.',
                },
            },
        },
        FlaggedImages: framework.FlaggedImages,
        ConfirmDeleteTitle: ``,
        ConfirmDeleteMessage: `Do you really want to delete the image? This process cannot be undone.`,
        Publish: 'Save & Publish',
        LastPublished: 'Last published: ',
        PublishSuccess: 'Your Pearl Page has been published',
        PublishSuccessUrl: 'Your Pearl Page URL is <url>{{display}}</url>',
        PublishFail:
            'Your webstore failed to publish. Don’t worry! Its not you. Try publishing it again.',
        PublishValidationError:
            'Oops! Looks like you’re missing some information. please edit before publishing.',
        PublishValidationErrorMessages: {
            about: 'basic information section is missing {{fieldNames}}',
            gallery: 'photo section is missing minimum of 1 image',
            amenities: 'selection needs to be made in services we offer section',
        },
        Alert: {
            UpdatePromptPrimary: framework.Alert.UpdatePromptPrimary,
            UpdatePromptSecondary: framework.Alert.UpdatePromptSecondary,
            UpdateBtnPrimary: framework.Alert.UpdateBtnPrimary,
        },
        TermsOfService: {
            BackToWebstore: 'back',
            TermsOfService: 'terms of service',
            Updated: 'updated',
            AcceptTerms:
                'I certify that I have read and agree to be bound to the <terms>Terms of Service</terms>, <privacy>Privacy Policy</privacy>, <arbitration>Arbitration Provision</arbitration> and <vendorAgreement>Vendor Agreement</vendorAgreement>.',
            Accepted: 'accepted',
            PublishPearlPage: 'publish pearl page',
        },
        Messaging: {
            ...framework.Messaging,
            NewMessages: 'You have new messages!',
        },
        Stripe: {
            CreatingStripeSession: 'creating stripe session',
            CreatingStripeSessionError: 'Error opening stripe portal',
        },
        PriceTable: {
            CallForPrice: 'Call for Price',
            HeaderTitle: 'pearl pricing',
            HeaderSubtitle: 'Join the fastest-growing wedding community and grow your business  ',
            FooterTitle: 'contact the Pearl Team',
            FooterSubtitle: 'Reach out to our Pearl Team who would be happy to assist you.',
            TextUs: 'Text Us',
            TextNumber: '38201',
            TextDisplay: 'PEARL to {{textNumber}}',
            CallUs: 'Call Us',
            PhoneNumber: '1-888-369-8514',
            EmailUs: 'Email Us',
            EmailAddress: 'pearlvendorsupport@davidsbridal.com',
            ALaCarteItems: {
                Title: `${BRAND} promos`,
                Subtitle: 'à la carte options to fit your business needs',
                Contact: 'contact sales',
                Items: [
                    'Dedicated social posts',
                    'Dedicated email blast',
                    'Print literature in local store tote bags',
                    'Direct contact with potential customers',
                    'Homepage ad',
                    'Category page ad',
                    'VIP placement in search listings',
                ],
            },
        },
        SocialLinks: framework.SocialLinks,
        Routes: routeTitles,
        PearlModalContext: {
            CloseAriaLabel: framework.PearlModalContext.CloseAriaLabel,
        },
        LoadingAdditionalContent: 'Loading...',
        LoadAdditionalContentBtn: 'Load More',
        Toast: {
            Success: 'Changes saved successfully',
            Error: 'Error saving changes',
        },
        Dropzone: {
            UploadPhotos: 'Upload Photos',
            UploadPhoto: 'Upload Photo',
            DropFiles: 'Drop files to upload',
            DropPhoto: 'Drop photo to upload',
            Or: 'or',
            AcceptedFileTypes: 'PNG and JPEG supported',
        },
        SearchButton: {
            VendorTitle: 'find a vendor',
            LocationTitle: 'location',
        },
        SearchDrawer: {
            LocationPlaceholder: 'search by location',
            VendorPlaceholder: 'what kind of vendor do you need?',
            SearchByVendors: 'search by vendors',
            TopCities: 'top cities',
            NoResultsFound: 'No results found',
        },
        VendorTileLearnMoreButton: 'learn more',
        FeaturedVendorBadge: 'featured vendor',
        FeaturedVendorBadgeAlt: 'star',
        CategoryInTitleAndLocation: '{{name}} in {{location}}',
        FooterSection: {
            Copyright: "&copy 2022 David's Bridal. All rights reserved.",
        },
        Filter: {
            ShowResults: 'show results',
            ClearFilter: 'clear filter',
            Reset: common.Actions.Reset,
            EventDate: {
                DrawerLabel: 'event date',
                FlexibleCheck: 'I`m flexible',
                EventQuestion: 'What is your timeline for planning your event?',
                DropdownPlaceholder: 'select',
                Option1: '6 months',
                Option2: '12 months',
                Option3: '12+ months',
            },
            PriceTiers: {
                DrawerLabel: 'price',
                Tier1: '$',
                Tier2: '$$',
                Tier3: '$$$',
                Tier4: '$$$$',
            },
            Styles: {
                DrawerLabel: 'style',
            },
            SubCategoryId: {
                DrawerLabel: 'subcategory',
            },
            Capacity: {
                DrawerLabel: 'capacity',
                Intimate: 'intimate <50 guests',
                Small: 'small 50 - 100 guests',
                Medium: 'medium 101 - 200 guests',
                Large: 'large 201 - 300 guests',
                ExtraLarge: 'extra large 300+ guests',
            },
            ServiceAmenities: {
                DrawerLabel: 'services & amenities',
            },
            ServiceAreas: {
                DrawerLabel: 'service area',
                Travels: 'travel to location',
                OnlineStore: 'online',
                InStore: 'in store',
            },
            Name: {
                Label: 'Search in {categoryLabel}',
            },
        },
        Sort: {
            PriceHighToLow: 'sort by price high to low',
            PriceLowToHigh: 'sort by price low to high',
            SortBy: 'sort by',
            MultipleResultsCount: 'results',
            SingleResultCount: 'result',
        },
        Breadcrumbs: {
            Home: 'Home',
            Vendors: 'Vendors',
        },
        Calendar: {
            MonthLabel: 'month',
            YearLabel: 'year',
        },
        Footer: {
            StartPlanning: 'All things planning. All in one App.',
            PlanningText: 'Download to start your party today',
            Follow: 'Follow',
            Help: 'Help',
            ContactUs: 'Contact Us',
            VendorFaq: 'Vendor FAQ',
            Faq: 'FAQ',
            TextUs: 'Text Us',
            Text: 'Text Pearl to {{textNumber}}',
            CallUs: 'Call Us',
            Information: 'Information',
            Blog: 'Ideas & Inspiration',
            VendorLogin: 'Vendor Log In / Sign Up',
            PressKit: 'Press Kit',
            CaResidents: 'CA Residents Only',
            AccessibilityStatement: 'Statement of Accessibility',
            Company: 'Company',
            Home: 'Home',
            AboutUs: 'About Us',
            Save: 'Ways to Save',
            DiamondLoyalty: 'Diamond Loyalty',
            LoyaltyText:
                '*See Loyalty program details & restrictions, gifts tier descriptions & disclaimers',
            Copyright: ` ${new Date().getFullYear()} ${BRAND}. All Rights Reserved.`,
            Privacy: 'Privacy & Cookies',
            Accessibility: 'Accessibility',
            Terms: 'Terms & Conditions',
            CCPA: 'California Privacy Rights',
            DoNotSell: 'Do Not Sell My Info - CA Residents Only',
            Sitemap: 'Sitemap',
            GooglePlay: 'Get it on Google Play',
            AriaLabels: {
                Facebook: 'Facebook',
                Twitter: 'Twitter',
                Instagram: 'Instagram',
                YouTube: 'YouTube',
                TikTok: 'TikTok',
                Pinterest: 'Pinterest',
            },
            Transparency: 'Transparency in Supply Chain',
        },
        NotFound: {
            Oops: 'oops!',
            Message:
                "Sorry, we couldn't find that page. Try exploring the categories below or go to <home>home page</home>.",
        },
        ZoomInfo: {
            CategoryHeader: 'Other <categoryLabel>{categoryLabel}</categoryLabel> in your city',
            VendorNotified: {
                Header: "We've notified",
                Body: 'This vendor is not yet on the pearl platform, but we have reached out to them on your behalf.',
                BackToSearch: 'BACK TO SEARCH',
            },
        },
        Admin: {
            ActionUnavailable: 'This action is currently unavailable for admins',
            PublishUnavailable:
                'This webstore has not accepted the TOS and cannot be published by admins',
        },
        OtherVendors: {
            CategoryHeader: 'Other {{categoryLabel}} you may like',
        },
        ShareVendor: {
            Links: {
                ...framework.SocialLinks,
                EmailLabel: 'email',
            },
            Share: 'Share',
            Back: 'go back',
        },
        SizzleReel: {
            Header: 'You know us for the dress, now we can help with the rest',
        },
        SubscriptionTypes: {
            Starter: 'Starter',
            Essential: 'Essential',
            Premium: 'Premium',
        },
        Authentication: {
            LoginSignupAriaLabel: 'Click to log in or sign up',
        },
        TravelCarousel: {
            CapHeader: 'vendors available for travel',
        },
        LocalCarousel: {
            CapHeader: 'vendors near you',
        },
    },
};

export default en;
